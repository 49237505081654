import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const HomeIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        The biggest single purchase most people make is their home and with that purchase the most difficult decision
        that may be faced with is what insurance company will provide the best coverage against fire, vandalism, falling
        trees and still be affordable.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        There is help. You do not have to depend on your real estate agent, your
        neighbors or even your past decisions if you have previously purchased a home. Rateinsider.com can make the
        process
        much easier by asking a few questions and then connecting you with insurance agents in your area. Quotes for the
        cost of the coverage will be provided, and you will have a good starting point for determining which agent
        offers
        the best combination of affordability and coverage.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        It is important that you ask the right questions so that
        nothing is missed and there are no misunderstandings if you need to file a claim. The following list of
        frequently
        asked questions can help you get started.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="Do all homeowners need homeowner’s insurance?">
        <p>
          If your home has a mortgage, the mortgage company is going to insist you
          have coverage. This coverage will pay for the repairs of your home, resulting from vandalism, fire, weather
          and
          other situations.
        </p>
        <p>
          Homeowner’s insurance does not cover flooding. If the home is located in a flood zone,
          a separate policy underwritten by the federal government will be needed. Homeowner's insurance covers water
          damage in some cases, such as leaking pipes or roof. Rising water, such as a river or an overflowing storm
          drain
          will be covered by flood insurance. Storm driven water, which might enter a home through a window broken by
          the
          storm, would be covered under most homeowner’s policies.
        </p>
      </AccordionItem>

      <AccordionItem title="What amount of insurance is needed?">
        <p>
          Normally, a homeowner’s insurance policy will cover the value of the home,
          as determined by the appraisal the mortgage company will conduct. In addition, most policies include an
          escalation clause that increases the value of your coverage as the value of your home increases.
        </p>
      </AccordionItem>

      <AccordionItem title="What do I do if I think I need greater coverage?">
        <p>
          You also have the option of insuring your home and contents or just the
          home. If you have any high-value possessions, such as some diamonds (depending on size and value) antiques or
          other similar objects, additional coverage will be offered.
        </p>
      </AccordionItem>

      <AccordionItem title="How are the annual rates for the insurance policy determined?">
        <p>
          The annual rates for the homeowner insurance will be determined by a number
          of factors, such as average property values for the area, location and other variables that differ from state
          to
          state. Most states have a regulatory agency that oversees the insurance industry to make certain you are not
          purchasing more insurance than you actually need.
        </p>
      </AccordionItem>

      <AccordionItem title="Are the policies hard to understand?">
        <p>
          A homeowner’s insurance policy will have two parts. There is the basic
          policy that the company uses to explain how they determine value, process claims and other matters. There is
          also a declaration page. This page shows the value of your home, the amount of insurance being purchased and
          how
          it is being paid.
        </p>
      </AccordionItem>

      <AccordionItem title="How do I make escrow and insurance payments?">
        <p>
          Most people purchasing a home will allow the mortgage company to set up an
          escrow account. Then the insurance premium becomes part of the monthly payment. The mortgage company will set
          aside the portion added to cover the annual insurance premium.
        </p>
        <p>
          Premiums can increase and as they do the
          house note will increase to keep up with any the higher of insurance. Furthermore, property taxes levied by
          the
          local governmental entities, will be collected with your monthly house note and paid for you at the end of the
          year when tax collections are due.
        </p>
        <p>
          Both the insurance and taxes are mandatory, if the house has a
          mortgage. It is better to let the items be paid through the escrow account set up by the mortgage company or
          in
          some cases a separate escrow company.
        </p>
      </AccordionItem>

      <AccordionItem title="How does Rateinsider.com work?">
        <p>
          Rateinsider.com, makes the task easy for you with a simple process.
        </p>
        <ol>
          <li>Fill out a simple form that asks questions that will only require a few minutes of your time.</li>
          <li>Tell us about the approximate price you plan to pay for your new home, the location and the insurance
            company you have on your present home, if applicable.
          </li>
          <li>Next we will start looking for companies or agents in your area that and provide the policy that will be
            the best fit for your new home.
          </li>
          <li>A list of insurance companies will be provided to you to get quotes from directly, while waiting to be
            contacted by agents and companies we have selected for you.
          </li>
          <li>Don’t just take the first company you contact. Talk to several companies, they may have some special
            offers that will prove to be beneficial.
          </li>
        </ol>
      </AccordionItem>

      <AccordionItem title="Is Rateinside.com an insurance company?">
        <p>
          Rateinsider.com does not sell insurance. The company provides a service that
          helps people buying a home get in contact with a group of insurance agents. From that group, the homeowner buy
          can select who they want as an agent.
        </p>
        <p>
          The selected agent will represent and assist the homeowner with many
          of the issues associated with the purchase of a home. These areas include recommending inspectors, checking to
          see if any home business you have is in compliance with local zoning laws and all the other matters that arise
          from the purchase of a home.
        </p>
      </AccordionItem>

      <AccordionItem title="Is Rateinsider.com free to use?">
        <p>
          RateInsider.com is free. We do not charge you anything. It is our goal to
          help you find the best price possible. This is done through the contacts we have established and calls we will
          make to find the right agents for you to consider. The insurance companies pay us a fee when we provide them
          with referrals who select a company we recommended.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);