import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const HealthRatesFaq = () => (
  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        Until just recently, everyone was not required to have health insurance coverage. There is still not an absolute
        requirement, but there is a penalty that must be paid if health insurance is not secured. This is the result of
        the Affordable Care Act (ACA) initiated by President Barack Obama. While the plan is facing legal and political
        challenges, it is the law and the passage of this new law changed the way insurance is sold. The plan has been
        approved by both Houses of Congress and the U.S. Supreme Court.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        The main changes are that no one can be
        denied health coverage because of their past medical history or age. The Affordable Care Act changed the insurance
        industry in many ways. Therefore, the examination of the nine questions a person might have asked in the past, are
        perhaps more important today.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="Why do you need health insurance?">
        <p>
          A person needs health insurance to help cover the medical costs associated
          with being ill or injured. There are people who will state that they never get sick and therefore, do not need
          health insurance. That is taking a gamble and there is always the possibility of an accident that is not covered
          by car insurance, homeowner's policies or any other type of insurance. Besides cars and homes, people insure
          boats, jewelry and other valuables. Therefore, it just makes good sense to insure your health. Without health
          insurance a person either has to pay his entire medical costs or look to the charity and public assistance.
        </p>
      </AccordionItem>

      <AccordionItem title="How do you get health insurance?">
        <p>
          Ultimately, most people purchase insurance. The purchase can be made by an
          individual from a private insurance company. It can come from a group insurance policy offered by an employer
          where the employee pays a portion to cover himself and his family. Insurance can also be purchased through a
          system called insurance exchanges, which were mandated by the federal Affordable Care Act.
        </p>
        <p>
          Many states
          have exchanges and there is a national exchange. Each exchange will offer a number of policies, and the premium
          is set by law. Depending upon your income, federal assistance in paying the premium might be provided.
        </p>
        <p>
          With the Affordable Care Act in place, low income people can receive federal assistance to cover the cost of
          the premiums. The amount of assistance will depend on income, family size and how your income relates to the
          certain factors. There is a website that will help you calculate how much assistance you may need. However,
          there is an enrollment period every year. Therefore, it is important to be aware of the enrollment windows in
          order to take full advantage of the coverage options. Also, coverage provided by the Affordable Care Act
          ceases, in most cases, at 65, when a person is eligible for Medicare.
        </p>
      </AccordionItem>

      <AccordionItem title="Which type of health insurance is right for you?">
        <p>
          There are different levels of insurance. At the entry levels, the premiums
          are lower but the deductible, the portion the policy holder pays before the insurance takes over, are higher.
          This type of policy only covers a portion of the cost of a hospital visit. These plans are sometimes offered to
          people, who have been denied coverage by traditional insurance companies.
        </p>
        <p>
          Passage of the Affordable Care
          Act guarantees that a person cannot be turned down for his age or prior health conditions. Premium adjustments
          are limited. Older people can only be charged three times more than younger people.
        </p>
      </AccordionItem>

      <AccordionItem title="What is Consumer-Directed Coverage?">
        <p>
          This is something of a hybrid. People using this plan will utilize Health
          Savings Accounts or Health Reimbursement to pay for routine health care expenses, while a high-deductible health
          plan provides coverage for catastrophic medical expenses.
        </p>
        <p>
          Under this plan, patients choose their doctors.
          The doctors do not have to be enrolled in an insurance health plan. The consumer can have a stronger voice in
          the treatment method and other factors. However, if you go to the same doctor all the time, the actual savings
          may be limited. There is also an issue with getting medicine and certain prohibitions about contacting doctors
          in other states via the Internet.
        </p>
      </AccordionItem>

      <AccordionItem title="How does Medicare Coverage work?">
        <p>
          Medicare is a federal program designed primarily for people 65 and older.
          Persons with certain diseases can be covered and children born with particularly severe health are also covered.
          There are several parts of medicate labeled A, B, C, and D. Part A is referred to as original Medicare. People
          in the work force pay a small premium, which is taken as a deduction from the paycheck. Upon reaching 65, a
          person can enroll in Medicare Part A which will cover all hospital visits.
        </p>
        <p>
          There is no premium to pay.
          However, Medicare only covers 80 percent of the hospital cost. The patient is responsible for the remaining 20
          percent. Many people purchase a Medicare Supplement or Medigap policy to cover that expense.
        </p>
        <p>
          Medicare
          Part B covers the expenses associated with seeing a doctor, lab work and similar expenses. There is a small
          premium that is normally deducted from a person's Social Security Check. There are limits as to what Medicare
          Part B will pay, which can be covered by the Medigap Policy.
        </p>
        <p>
          Medicare Part C involves the Medicare
          Advantage programs. Those programs provide the Medigap policy, handle the claims and offer other benefits,
          usually at a saving for the client. However, the use of these policies is generally limited to a geographic
          area. However, coverage is usually provided outside the area in the event of an emergency.
        </p>
        <p>
          Medicare Part D
          is the newest addition to Medicare and covers the cost of prescription drugs. This part can be combined with
          many of the Advantage plans. Part D has some limitations after a certain amount is paid out in benefits.
        </p>
        <p>
          After the mandated monetary value has been reached, the patient covers his medicine costs, or 'covers the
          donut hole' until a predetermined amount has been paid by the patient.After that the medication coverage
          resumes. Revisions to this part of Medicare are constantly being made, according to the Medicare Blog
          published by the Medicare program and can be found at the Medicare Website.
        </p>
      </AccordionItem>

      <AccordionItem title="What other government programs are available?">
        <p>
          There are health care programs for certain groups such as Veterans.
          Furthermore, Medicaid, a joint federal and state program that helps low-income-individuals and families with
          special needs, could be contacted.
        </p>
      </AccordionItem>

      <AccordionItem title="Are there other types of heal-related coverage?">
        <p>
          A new concept called concierge medicine. In this plan, a patient pays an
          annual fee to the physician for health care. There are no other costs associated with seeing the physician.
          There are some cases where several doctors with different specialties form a group and offer this coverage.
        </p>
        <p>
          However, this plan does not cover the cost of medicine or hospitalization. Furthermore, some concierge
          physicians treating Medicare patients will file Medicare claims, accepting whatever Medicare will pay but will
          not charge the 20 percent Medicare does not cover. The concept does give more face time with the doctors,
          usually no fee per visit and other benefits such as a private number to reach the nurse and reserved
          appointment times.
        </p>
      </AccordionItem>

      <AccordionItem title="What happens if you have a Pre-Existing Condition?">
        <p>
          Not too long ago, a person would possibly be denied coverage by a private
          insurer if there was a pre-existing medical condition. With the enactment of the Affordable Care Act,
          pre-existing conditions cannot be used to deny any patient coverage and cannot be used to justify an increase in
          costs.
        </p>
      </AccordionItem>

      <AccordionItem title="What happens if you have health insurance provided by your employer, and you leave your job?">
        <p>
          There are several possibilities. The coverage could terminate immediately.
          The coverage might be extended for a given period of time. A person could be switched to COBRA, which stands for
          a long federal name and requires that continuation of coverage to be offered to covered employees, former
          spouses, and dependents when group health coverage is lost for events such as divorce or dismissal.
        </p>
        <p>
          However, the employer is not required to pay any premium cost. Some employers will pay a portion or all,
          depending upon the circumstances dealing with the employee's departure. It allows the employee to continue the
          coverage for 18 months. In some cases, the COBRA policy can be converted to a private policy. Dismissed
          employees, depending on their monetary situation may be able to apply for Medicaid in their state. However,
          doctors are not required to accept Medicaid patients.
        </p>
        <p>
          Today a person could then contact any insurance
          company and purchase a policy, because he cannot be turned down. He could go through the Affordable Care Act
          Marketplace Exchange or a state exchange if available and apply for coverage there through a special
          enrollment, which generally covers, loss of job, divorce or marriage.
        </p>
      </AccordionItem>

    </Accordion>
  </Faq>
);