import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const MedicareIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <p className="text-base text-gray-700 md:text-lg">
      Knowing how to sign up for Medicare is just the tip of the iceberg. You also need to know what the insurance
      covers as well as what you are qualified for. These Frequently Asked Questions will help you learn more about the
      path that is best for you. You might find that you already qualify for Medicare, or that your current insurance
      plan is working better for you. Look to these answers for solutions to your insurance dilemmas.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="How does RateInsider.com work?">

        <p>
          RateInsider.com works with thousands of insurance agents who understand
          the world of medical insurance. In following these six simple steps, you will be on your way to applying for
          and understanding Medicare and all its components:
        </p>
        <ol>
          <li> Fill out our simple form online. It asks easy questions that will take just a couple of minutes to
            answer.
          </li>
          <li> You will then be asked to provide information about your location, medical coverage and other
            essentials so that we can understand your individual needs. This helps us determine your eligibility and
            desires.
          </li>
          <li> Once RateInsider.com has collected all the necessary information, we will get to work searching for the
            right process and qualifications to suit your needs. You may find that working through a private insurer
            is the best bet for your needs.
          </li>
          <li> While you are waiting for further information that we have found, we will also provide you with
            information regarding medical insurance and quotes you may be eligible for.
          </li>
          <li>If you are eligible for Medicare, you will then undergo the process of filling out forms and determining
            whether or not you need to find another policy. We always help our users find the best insurance policies
            out there.
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Is Ratensider.com an insurance company?">

        <p>
          No. RateInsider.com is in no way a policy provider. Rather, we are a free
          insurance referral site that is dedicated to ensuring our users succeed in finding quality medical care. We
          are passionate about helping our users qualify and sign up for Medicare and other forms of health insurance.
          Any issues you have with your insurance company require contact through the company or agent you are working
          with. When Medicare is concerned, you may be referred to the Social Security Administration for answers to
          questions regarding your specific plan.
        </p>
      </AccordionItem>
      <AccordionItem title="Can I use RateInsider.com for free?">

        <p>
          Yes. RateInsider.com is a helpful tool available for free. We are here to
          help you navigate the process of signing up for Medicare and other forms of insurance. We pride ourselves in
          this ability to provide a successful referral at no cost to the users.
        </p>
      </AccordionItem>
      <AccordionItem title="What is Medicare?">

        <p>
          Medicare is a form of health insurance provided by the federal government
          for people deemed qualified. These individuals must be either over the age of 65 or under 65 with certain
          disabilities, which may include Lou Gehrig’s disease and end-stage renal disease. Ultimately, Medicare
          includes medical, hospital and prescription drug coverage alongside certain Medicare Advantage plans.
          Supplemental and medication plans are often available through private insurers that you might already be
          working with.
        </p>
      </AccordionItem>
      <AccordionItem title="How does Medicare work?">

        <p>
          Individuals who qualify for Medicare can sign up to receive the federal
          health insurance, though most are automatically enrolled in Medicare Parts A and B upon reaching the age of
          65. These types of Medicare cover hospital stays and medical services. Those who wish to enroll in private
          Medicare may also apply for supplemental coverage, including Medicare Advantage and Part D. Some of these
          plans may be provided through private insurance companies you are already familiar with.
        </p>
        <p>
          Medicare is
          different for each individual, with some plans requiring deductibles and others expecting patients to pay
          out-of-pocket expenses. You may find that the benefits of your plan differ from those of others using
          Medicare. For this reason, it is a good idea to speak with private insurers before making big changes to your
          current plan.
        </p>
      </AccordionItem>
      <AccordionItem title="What do the different parts of Medicare cover?">

        <p>Medicare is broken down into four segments, each one with its own type of coverage.</p>
        <ul>
          <li>Part A, hospital insurance, covers inpatient services that are deemed medically necessary. If you have
            surgery or resting, this may provide the necessary coverage.
          </li>
          <li>Part B, medical insurance, covers services like an ambulance or preventative care. This is similar to
            plans that cover visits to the doctor or treatment for an illness.
          </li>
          <li>Part C, Medicare Advantage, is offered by private health insurers. It covers Part A and Part B, but
            additional plans may include routine dental coverage and vision plans. These are specific plans that you
            can add on to your current insurance plan.
          </li>
          <li>Part D, prescription drug coverage, is offered by private health plans and covers the cost of
            prescription medications. It is not typically included with Part A and Part B.
          </li>
          <li>Medicare Supplement Plans are offered by private health insurers, and they are used to fill in the areas
            that Part A and Part B don’t cover.
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="Do I need to sign up for Medicare Part A and Part B if I still work?">

        <p>
          It depends on your situation. When it comes to Part A and you are working
          over the age of 65, you should sign up for Medicare. Even if you have a group health plan, Part A can still
          help you pay for hospital services. If you or your spouse have paid Medicare taxes for 10 years or longer, you
          will not be required to pay the monthly premium.
        </p>
        <p>
          You do not need to sign up for Medicare Part B if you
          are still working and maintain group coverage through an employer or union. It is best to consult with your
          benefits administrator to compare your current coverage with Medicare’s coverage. In many cases, Part B works
          as a primary payer if you are working with a small company.
        </p>
      </AccordionItem>
      <AccordionItem title="What benefits do supplemental plans provide?">

        <p>
          Medicare’s supplement plans offer additional benefits, including a 20%
          co-pay that many patients find easier to manage than others. On the other hand, this type of Medicare does not
          cover medications. In order to receive prescription coverage, these individuals must consider Part D, which
          many private insurers do offer.
        </p>
      </AccordionItem>
      <AccordionItem title="Am I allowed to visit any hospital or doctor if I have a supplemental plan?">

        <p>
          Medicare supplement members are allowed to visit any doctor or hospital
          they wish, so long as their intended medical provider accepts Medicare insurance. Fortunately, supplemental
          plans are recognized across the country, so finding a doctor is not typically a problem.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);