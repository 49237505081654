import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const HealthIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        Finding the right health insurance can be a difficult and tiresome experience, but it does not have to be so
        stressful. You do not have to spend hours comparing quotes to find the best price, moving from company to company
        in search of a great deal. It takes time to fill out all those forms, especially when you are trying to remember
        rates and coverage types from every company you’ve looked at. For your convenience, RateInsider.com simplifies it
        all.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        Not only will RateInsider.com eliminate the hassle that comes with compiling health insurance quotes,
        but you can also count on it to save money. Did you know that millions of people are currently paying way too much
        for their insurance policy? They are paying for extra items that just are not necessary. If they were using
        RateInsider.com, they would know that they are spending way too much money on insurance they will never use.
        RateInsider.com connects users with insurance agents who can provide accurate quotes for insurance you can
        actually
        use. Shopping for health insurance is finally quick, easy and totally painless. Just take a few minutes to fill
        out
        the form to get started.
      </p>

      <p className="text-base text-gray-700 md:text-lg">
        Price is an important consideration when it comes to health insurance. Comparison shopping helps you find the
        best deals on insurance, but there is so much more to finding health insurance. As you begin to consider different
        health insurance quotes, it is wise to learn more about the basics of health insurance. You can do this by reading
        some of our Frequently Asked Questions and their answers so you can figure out your next steps.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="How does RateInsider.com work?">
        <p>
          RateInsider.com has relationships with thousands of health insurance
          companies, with agents representing the best brands out there. Follow five simple steps to save hundreds of
          dollars each year on health insurance.
        </p>
        <ol>
          <li>Fill out the simple form on our website. The questions are simple, and it should take you just a few
            minutes.
          </li>
          <li>Let us know what kind of health insurance you need, your location and what kind of policy you currently
            have.
          </li>
          <li>As soon as RateInsider.com has the necessary information, we will scour our resources to find the best
            insurance for you.
          </li>
          <li>Once we select health insurance providers for you, we will provide you a list of companies that will
            provide you with a direct quote.
          </li>
          <li>You now have the power to compare policies and pricing, ensuring you find the perfect policy for your
            needs. You are in control.
          </li>
        </ol>
      </AccordionItem>

      <AccordionItem title="Is RateInsider.com a health insurance company?">
        <p>
          RateInsider.com is not a provider for health insurance. We are simply a free
          health insurance referral service that hopes to connect people like you to affordable health insurance. You will
          not purchase a policy through us. You must purchase a policy through one of the insurance companies we refer you
          to. All support issues and questions should be addressed with the insurance company of your choosing.
        </p>
      </AccordionItem>

      <AccordionItem title="Can I use RateInsider.com for free?">
        <p>
          Yes! RateInsider.com is absolutely free -- and there is no catch. We are
          dedicated to helping people just like you. Our dedication is to helping individuals find health insurance that
          fits their needs without breaking the bank. We do this for no cost and are compensated by the insurance
          companies for which we provide referrals.
        </p>
      </AccordionItem>

      <AccordionItem title="What is health insurance?">
        <p>
          When you buy health insurance, you are actually paying for a contract that
          agrees the company will pay for all or some of your medical bills, depending on what kind of policy you have.
          The amount you pay, the premium, ensures the contract is ongoing. Private health insurance is the most common
          form, with many Americans receiving a policy through work. Some public insurance policies are available via
          Medicare and Medicaid.
        </p>
        <ul>
          <li>What is a deductible? - A deductible is a charge for medical service that you are required to pay each
            year before the insurance provider pitches in. Many plans have two deductibles, one for each individual and
            one for the family as a whole.
          </li>
          <li>What is a copayment? - A copayment is a fixed amount of money you are required to pay anytime you receive
            medical service. For instance, copayment may be $20 for each doctor’s visit and $30 for a specialist
            appointment.
          </li>
          <li>What is coinsurance? - Instead of making a fixed copayment, you may be required to pay a fixed percentage
            each time you visit the doctor’s office. For instance, you may be asked to pay for 20% of a doctor’s visit.
          </li>
        </ul>
      </AccordionItem>

      <AccordionItem title="What should I do if I can’t afford health insurance?">
        <p>
          Fortunately, you have options. Your income could make you eligible to
          receive low-cost, subsidized health insurance based on Obamacare. Medicaid and the Children’s Health Insurance
          Program also provide low-cost or free health insurance.
        </p>
      </AccordionItem>

      <AccordionItem title="Do I qualify for a subsidy to cut my insurance costs through Obamacare?">
        <p>
          It depends. If you are a single person who earns up to $46,000 each year or
          have a family of four and earn up to $94,200, you may be eligible for a tax credit to help cover the costs of
          health insurance. Of course, you are also required not to have an affordable option that is employer-sponsored.
          Additionally, individuals earning less than $28,725 and families earning less than $58,875 may receive
          assistance with out-of-pocket costs.
        </p>
      </AccordionItem>

      <AccordionItem title="Am I exempt from paying a penalty for not having health insurance?">
        <p>
          You may qualify for an exemption if you have been uninsured for less than
          three months, are part of a religion that objects to health insurance or heave a ministry that participates in
          health care sharing. You are also exempt if you make so little each year that you are not required to submit a
          federal tax return each year or if you may have qualified for Medicaid per the Affordable Care Act’s
          guidelines.
        </p>
      </AccordionItem>

      <AccordionItem title="What types of health insurance should I consider?">
        <p>Major Medical Plans tend to cover serious illness and injuries with high
          bills. This includes hospital and doctor visits plus medications. Several plans exist:</p>
        <ul>
          <li>Indemnity Plans - After the deductible is paid, the insurance company pays a percentage of the expenses.
            This is typically about 80% of the total cost, providing the most flexibility in finding a healthcare
            provider.
          </li>
          <li>Preferred Provider Organization Plans (PPO) - If you have a PPO, your insurance company has a contract
            with healthcare providers who provide more affordable rates. You can also find a provider who is out of
            network, but your deductible and co-pay are likely to be higher.
          </li>
          <li>Health Maintenance Organization Plans (HMO) - This type of plan requires that you choose a primary care
            physician to manage your care. You may receive a referral to seek care from another in-network provider. An
            HMO usually does not cover treatment from those out of the network.
          </li>
          <li>Point of Service Plans (POS) - This is a combination of the PPO and HMO plans, providing some flexibility
            while requiring a primary care physician.
          </li>
        </ul>
        <p>There are also limited benefit plans, which provide only limited coverage. Several options are available:</p>
        <ul>
          <li>Basic Hospital Expense Coverage - This insurance typically covers more than 31 days of continuous care in
            the hospital with only a few outpatient services covered.
          </li>
          <li>Basic Medical-Surgical Expense Coverage - This plan covers a necessary surgery in addition to more than
            three weeks of in-hospital care.
          </li>
          <li>Hospital Confinement Indemnity Coverage - This plan covers a daily cost associated with a hospital stay.
            It covers a fixed amount each day.
          </li>
          <li>Accident Only Coverage - This is the insurance plan that covers medical care, disability and hospital
            stays caused by an accident. It may also cover death-related costs.
          </li>
          <li>Specified Disease Coverage - This insurance covers the cost of a diagnosis and treatment for a specific
            illness or disease.
          </li>
          <li>Other Limited Coverage - Individuals may choose to purchase insurance that covers specific care, such as
            dental or vision health.
          </li>
        </ul>
      </AccordionItem>

      <AccordionItem title="Additional Coverage Options">
        <p>
          Some insurance policies are intended to provide care for those who have
          become disabled or require long-term care. Here are those options:
        </p>
        <ul>
          <li>Disability Income - This insurance policy provides payments on a weekly or monthly basis while the
            individual is disabled following an injury or illness. The payment has a fixed amount based on the
            individual’s income.
          </li>
          <li>Long-Term Care Insurance - These plans cover care in a nursing home, adult day care center or assisted
            living home. The policy covers a fixed amount.
          </li>
          <li>Medicare Supplemental Coverage - While Medicare typically covers expenses for individuals over the age of
            65 or who receive Social Security disability, it cannot cover everything. This will help cover other
            expenses, like deductibles.
          </li>
          <li>Cancer Insurance - Several types of cancer insurance are available, but it is wise not to consider any of
            these policies comprehensive. A major medical policy is typically recommended alongside cancer insurance,
            especially because extra coverage is often a necessity.
          </li>
        </ul>
      </AccordionItem>
    </Accordion>
  </Faq>
);