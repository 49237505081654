import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const PetRatesFaq = () => (
  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        Nowadays pets are more than just companions, they are family, and you always want the best of everything
        for your family. Don't leave out your furry friends when shopping around for insurance - pet insurance is just as
        important as any other in case of an emergency. The questions below cover some of the most frequently asked
        questions regarding pet insurance, and they can help put you in the right direction before something unexpected
        happens.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        Before looking for a plan, you need to know exactly what you want for your pet so you can find the
        best insurance plan for your specific needs. There are a lot of options out there so make sure you are
        well-informed and prepared to look through them by starting here with your search.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="How do you get pet insurance?">
        There is no enrollment period for pet insurance, unlike our health
        insurance, so you can get quotes at any time online, pick a plan that is right for your pet, and enroll.
      </AccordionItem>

      <AccordionItem title="What does pet insurance actually cover?">
        Pet insurance will cover the treatment of major illnesses, emergencies, and
        accidents and also might include some preventative care if that is part of your plan. Of course, these
        specifics vary by plan so it is important to compare and see what is the best fit for your pet's needs.
      </AccordionItem>

      <AccordionItem title="Do I need to schedule a veterinarian exam prior to enrolling?">
        Some pet insurance companies do require a physical exam prior to acceptance,
        so it is a good idea to have had a recent trip to the vet or one coming up soon.
      </AccordionItem>

      <AccordionItem title="What other records might the insurer ask for?">
        There will likely be a request for your pet's medical records, including the
        aforementioned physical exam. If there isn't an outright request for them, it is possible that they will
        instead request them directly from your vet.
      </AccordionItem>

      <AccordionItem title="How long is my pet covered for?">
        Your pet will be covered for life as long as you are paying your premiums on
        time and following any guidelines put forth by the insurance company.
      </AccordionItem>

      <AccordionItem title="How do I know if my vet's office accepts my pet insurance?">
        Any licensed vet clinic in the United States will accept pet insurance of
        any kind. The claims process is much different for pets than humans, and therefore allows your pet to see any
        veterinarian - this also would include any emergency or specialty vet clinics.
      </AccordionItem>

      <AccordionItem title=" When does my insurance coverage start?">
        There is a waiting period with enrollment into pet insurance. It can be from
        two weeks to up thirty days depending on the type of coverage you are receiving. Most pet insurers will have
        preventative care kick in right away, while accident and illness coverage can take a few weeks.
      </AccordionItem>

    </Accordion>
  </Faq>
);