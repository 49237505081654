import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const RentersQuotesFaq = () => (
  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        It is imperative to know the type of insurance you need when shopping for renters insurance. If you do not
        understand how the policies work, you may buy coverage that does not suit your needs and expectations. That is
        why you need to do due diligence in finding relevant information before shopping for renters insurance.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        The best way to manage your renters insurance policy is by going through the above questions to learn more about
        the
        policy. You will clear all doubts about renters insurance and be able to make informed decisions.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="What is the cost of renters insurance?">

        <p>
          Renters insurance is relatively cheap compared to other insurance
          policies. The cost depends on various factors such as your credit rating, your location, policy type, and the
          items you plan to insure.
        </p>
        <p>
          Do an actual cash value and replacement cost renter's insurance policy have
          any similarities?With actual cash value renters insurance, a renter gets paid based on the insured item's
          current value. Replacement renters insurance will cover the costs of the item that you need to replace or
          repair. The payout for replacement renters insurance is higher because it comes with higher premiums.
        </p>
      </AccordionItem>

      <AccordionItem title="Are the items outside your apartment covered by renters insurance?">

        Yes. The policy covers all your items regardless of where they are, whether
        you're on a trip or vacation.
      </AccordionItem>

      <AccordionItem title=" What does renters insurance consist of?">

        Renters insurance covers personal liability coverage, medical coverage loss,
        and damage of personal property.
      </AccordionItem>

      <AccordionItem title="Can the insurer deny my renters insurance claim?">

        Insurers rarely deny the renter's insurance claim except for various
        reasons, such as violating the policy's terms.
      </AccordionItem>

      <AccordionItem title="How do you differentiate a named perils and all risk centers insurance policy?">
        Named perils coverage takes care of the items stated in the policy, while an
        all-risk covers things not stated in the policy.
      </AccordionItem>

      <AccordionItem title="What elements does renters insurance cover?">

        Renters insurance covers vandalism, fire, snow, ice, smoke damage, riots,
        theft damages from falling objects, and hail.
      </AccordionItem>
    </Accordion>
  </Faq>
);