import React from "react";
import { AccordionItem } from "../../AccordionItem";
import { Accordion } from "../../Accordion";
import { Faq } from "../../Faq";

export const CarIndexFaq = () => (
  <>
    <Faq hidePretext={false} preText={(
      <>
        <p className="text-base text-gray-700 md:text-lg">
          Shopping for car insurance can be a tedious, tiresome and stressful experience. Especially, when you are trying
          to get the best price by comparing quotes from multiple companies. Filling out confusing forms, trying to keep
          track of rates and coverage from different insurance companies can be impossible. This is where RateInsider.com
          comes in to simplify the entire process.
        </p>

        <p className="text-base text-gray-700 md:text-lg">
          RateInsider.com not only helps to remove the hassle of comparing
          auto insurance quotes, but it can also help to save money. Millions of people are overpaying on their auto
          insurance
          policies, mainly because they are paying for extras they don't need. RateInsider.com helps to avoid those extra
          costs by gathering information from you through a short series of simply worded questions, then connects you to
          insurance agents who will provide quotes for optimal coverage. RateInsider.com makes shopping for car insurance
          easy, and quick, as it only takes a few minutes to fill out the information we need to go to work.
        </p>

        <p className="text-base text-gray-700 md:text-lg">
          Car insurance is not just a product that protects your assets, it is a product that virtually all states require
          that you purchase and maintain. While car insurance is generally a state-mandated requirement, it can be a complex
          product that is difficult to understand. This is why it is imperative that consumers buying insurance for the
          first time educate themselves on the product and the insurance market before they buy a policy that leaves them
          vulnerable when they are behind the wheel.
        </p>

        <p className="text-base text-gray-700 md:text-lg">
          When it comes to auto insurance, price is extremely important.
          While everyone should comparison shop to find the best deals, price should not be the only factor that you are
          concerned about. Before you even begin to request and compare auto insurance rate quotes, you should get started
          by
          learning the auto insurance basics. Here are some of the most popular Frequently Asked Questions and the answers
          that will help steer you in the right direction:
        </p>
      </>
    )}>
      <Accordion>
        <AccordionItem title="Do All Drivers Need Auto Insurance">
          <p className="text-base pb-3">
            When you are newly licensed, it is only natural to wonder if you truly have a
            need for auto insurance. There may be parts of an auto insurance plan that you do not need, but in virtually
            every
            state in the nation it is illegal not to carry a minimum amount of liability insurance when you are
            operating a
            registered vehicle on public roads.
          </p>
          <ul className="list-disc list-inside">
            <li className="text-base pb-3">State Requirements. Liability insurance is typically required in states that
              operate under a tort system.
              Tort-based systems require that the driver who is deemed at-fault for an accident pay for the damages that
              they cause. Since liability insurance covers damages to a third-party, it helps to ensure that anyone with
              a
              driving privilege will be able to pay for a limited amount of damages. In no-fault states, you may also be
              required to carry Personal Injury Protection, which pays for your own medical bills regardless of who is
              at-fault.
            </li>
            <li className="text-base pb-3">Financial Risk Protection. The odds of you getting into an accident at some
              point in time throughout your
              life are pretty high. The more you drive, the higher these odds get. Insurance is more than just a
              state-mandated requirement that vehicle owners must comply with, it is also a form of protection that
              these
              drivers need. A major accident, and even sometimes a minor one, can lead to major financial obligations
              that
              can affect you for years if you do not have adequate coverage. Liability coverage will help protect your
              finances, wages and assets if your limits are high enough. If you add on additional forms of protection,
              you
              can also breath a sigh of relief that you do not have to pay thousands of dollars to repair your car or to
              buy
              a new one. If you want to pass the financial risk associated with driving on to a large, wealthy entity,
              you
              can do this by buying a fully comprehensive plan.
            </li>
          </ul>
        </AccordionItem>
        <AccordionItem title="Is the Minimum Liability Limit Enough?">
          <p className="text-base pb-3">
            Insurance is not one-size-fits-all. There are, however, liability limits that
            you will be required to carry by law. These minimum limits will ensure that you are complying with the auto
            insurance liability laws, but they do not mean that you will be sufficiently covered. You must consider what
            assets you have to protect and what the financial consequences could be if you were in a catastrophic loss
            and
            chose to carry minimum Property Damage and Bodily Injury limits.
          </p>
          <p className="text-base pb-3">
            Generally speaking, agents will recommend
            that you carry no less than $100,000 per person and $300,000 per accident in Bodily Injury. These experts
            also
            recommend carrying at least $50,000 in Property Damage coverage so that you have protection in case you are
            in an
            multi-car accident. If you have a home and other assets to protect, consider higher limits. A general rule
            of
            thumb is that the coverage level you should carry will get higher as your assets grow. This helps ensure
            they are
            not vulnerable if you are sued because of damage that you cause in an at-fault accident.
          </p>
        </AccordionItem>
        <AccordionItem title="What Types of Auto Insurance Coverage Can I Purchase?">
          <p className="text-base pb-3">There are several coverage options that you can choose from when building your
            own auto insurance policy. Some of the options are mandatory and others are considered to be optional
            depending on
            legislation in your state. Here is a breakdown of the options and how they work to protect you or a
            third-party
            driver:</p>
          <ul className="list-disc list-inside">
            <li className="text-base pb-3">Bodily Injury and Property Damage. Bodily Injury (BI) and Property Damage
              (PD) are the two coverage types
              that make up the liability coverage category. Each is required by law and the law will mandate how much
              coverage you can carry at a minimum. Bodily Injury will pay for damages when someone in the other vehicle
              is
              injured or dies because of the injuries sustained in the accident. It may also pay for a pain and
              suffering
              judgement in states where this is permitted. Property Damage will pay up to the limits on your policy for
              the
              repair or replacement of third-party vehicle(s).
            </li>
            <li className="text-base pb-3">Uninsured Motorist/Underinsured Motorist. Some states now require drivers to
              carry Uninsured Motorist
              protection (UM) due to the fact that there are so many uninsured drivers on the roadways. While uninsured
              drivers are penalized and fined, it is not out of the ordinary for the rates of drivers without insurance
              to
              be as high as 20%. To protect yourself against these irresponsible individuals, you can carry UM which
              will
              pay for your medical bills if the at-fault party did not have liability coverage or had low limits that
              would
              not cover all of your medical bills.
            </li>
            <li className="text-base pb-3">Medical Payments or Personal Injury Protection. In no-fault states, where
              parties pay for their own medical
              bills regardless of fault, Personal Injury Protection (PIP) is a requirement. This will pay up to a stated
              amount for your passengers' or your medical bills, treatment, rehabilitation, and lost wages. Medical
              Payments
              is available in states where the at-fault parties pay for the damages that they cause. In these states,
              you
              have the option to buy Medical Payments (MedPay) to cover a limited amount medical costs. MedPay pays out
              no
              matter who is at-fault so that you can get immediate care even if you do not have health insurance.
            </li>
            <li className="text-base pb-3">Comprehensive and Collision. Comprehensive and Collision are two forms of
              Physical Damage cover that are not
              required by law. They each pay for the repair or replacement of your vehicle when it is damaged or
              totalled.
              Comprehensive will pay if the damage was due to a fire, theft, vandalism, collision with live animal,
              glass
              breakage or other non-moving losses. Collision will pay when the vehicle is being operated. The state does
              not
              care if you carry Physical Damage cover, but if your vehicle is leased or financed the lessor/lender will.
              Your contract requires you to maintain full coverage to protect the lender's interest in the car until it
              is
              paid off. Keep this in mind as you quote car insurance.
            </li>
          </ul>
        </AccordionItem>
        <AccordionItem title="How Will My Rates Be Determined?">
          <p className="text-base pb-3">The insurance industry is all about risk, and to calculate your rates the
            insurer must determine how much risk you present and how likely you are to get into an accident. To do this,
            the
            agent will ask you many questions. The answers will be used to personalize your rates. Many different
            factors can
            either raise or lower your base premium.</p><p className="text-base pb-3">Some of the factors that will
          affect your rates include:</p>
          <ul className="list-disc list-inside">
            <li className="text-base pb-3">Age, gender, years of driving experience</li>
            <li className="text-base pb-3">Safety record of the vehicle</li>
            <li className="text-base pb-3">Driving record and accident record</li>
            <li className="text-base pb-3">Annual mileage</li>
            <li className="text-base pb-3">Vehicle usage</li>
            <li className="text-base pb-3">Occupation</li>
            <li className="text-base pb-3">Garaging zip code</li>
            <li className="text-base pb-3">Credit score</li>
            <li className="text-base pb-3">Types of coverage and limits that you carry</li>
          </ul>
        </AccordionItem>

        <AccordionItem title="Do You Have Advice For Me When Buying a New Policy?">
          <p className="text-base pb-3">
            Many people focus strictly on price, but you also need to be sure that you
            build a good policy and do business with a trusted carrier. Your policy is only as good as the provider you
            buy it
            through. You should be sure to check into the company's financial rating and their complaint index before
            you
            trust that they will cover claims that you file.
          </p>
          <p className="text-base pb-3">
            If you want to keep your premiums low, there are ways to do
            so without buying a policy with the bare minimums. You may want to carry a high deductible to keep down your
            rates
            if you are a young or risky driver. If you have experience in another state, get your previous license
            number for
            more experience credits. If you need property, life or health insurance, do business with a company that
            offers
            all of them and you can get multi-line discounts. Insurance all of your vehicles with the same company and
            get
            multi-car rates. There are several ways to save without dropping down your limits.
          </p>
        </AccordionItem>
        <AccordionItem title="What Is the Best Way to Price Auto Insurance Rates?">
          <p className="text-base pb-3">There are several ways that you can price rates with several different
            insurers. Comparing the rates through 3 or more insurers is best when you want to find a bargain. The most
            effective way to compare rates through more than one insurer is to use an online rate comparison tool that
            will
            connect you with multiple reputable insurers and give you the rates instantly.</p>
        </AccordionItem>
        <AccordionItem title="How does RateInsider.com work?">
          <p className="text-base pb-3">We work with thousands of insurance agents who represent top auto insurance
            company brands. By following these six simple steps you could be quickly on your way to saving hundreds of
            dollars
            a year:</p>
          <ol>
            <li className="text-base pb-3">Fill out our simple online form. It asks simple questions and only takes a
              few minutes to complete.
            </li>
            <li className="text-base pb-3">In order to get connected to the best insurance providers in your area, you
              will need to provide amount of
              desired protection, your location, current insurance policy and driving habits.
            </li>
            <li className="text-base pb-3">Once RateInsider.com has all the information needed, we go to work searching
              for the insurance companies or
              agents providing policies that best match your needs.
            </li>
            <li className="text-base pb-3">While you are waiting to be contacted by the insurance providers we have
              chosen for you, you will see a list
              of insurance companies to get quotes directly from.
            </li>
            <li className="text-base pb-3">With different people offering you different prices, be sure to compare
              policies and pricing. You may be
              able to get a great deal on a killer policy, just because you checked out a couple places first.
            </li>
            <li className="text-base pb-3">Make sure to consider any extras that may be offered by auto insurance
              companies, such as 24/7 customer
              service and Road Side Assistance.
            </li>
          </ol>
        </AccordionItem>
        <AccordionItem title="What information is required to get car insurance quotes?">
          <p className="text-base pb-3">Below is the information our partner insurance agents need in order to provide
            you with an insurance quote:</p>
          <ul className="list-disc list-inside">
            <li className="text-base pb-3">Basic contact information includes your name, house address, phone number,
              and email address.
            </li>
            <li className="text-base pb-3">The information from the vehicle needed is the year, make, model, current
              mileage, owner status, and the
              estimated yearly mileage driven.
            </li>
            <li className="text-base pb-3">Information on the driver needed is the driver's age, education, and
              occupation, any accidents, violations,
              or claims from the last five years.
            </li>
            <li className="text-base pb-3">Your current insurance policy information is required, if any.</li>
          </ul>
        </AccordionItem>
        <AccordionItem title="What happens after RateInsider.com provides quotes?">
          <p className="text-base pb-3">Once all this information has been provided to us, there is only one more
            thing you will need to do. Sit back and relax. Before long the agents we matched you with will begin sending
            you
            quotes for your auto insurance. Once the quotes come in, its up to you to choose which policy you like the
            best or
            which price best fits your budget. RateInsider.com took care of the hard part, the initial contact with the
            proper
            companies.</p>
        </AccordionItem>
        <AccordionItem title="Is RateInsider.com an insurance company?">
          <p className="text-base pb-3">RateInsider.com is in no way, shape, or form an auto insurance policy
            provider. We are a free auto insurance referral site dedicated to helping anyone succeed on the quest to
            locate
            the best price on their auto insurance. All insurance policies must be purchased through one of our referred
            insurance companies or agents. Any support issues, questions, claims, must be handled through the insurance
            company or agent you chose.</p>
        </AccordionItem>
        <AccordionItem title="Is RateInsider.com free to use?">
          <p className="text-base pb-3">Another great thing about RateInsider.com is the fact that it is totally,
            completely, 100% free. Yes, free. We are here to help you. This site is dedicated to helping individuals
            find the
            auto insurance policy that fits their needs best, and we pride ourselves in providing this service to
            individuals
            at no cost. We are compensated by our insurance companies and agents when we provide them with successful
            referrals.</p>
        </AccordionItem>
      </Accordion>
    </Faq>
  </>
);