import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const RentersIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        The process of getting renters insurance is challenging and tiring. This can be more tedious when looking
        for the best prices because different insurers offer different policies. You will also need to go through a
        thorough process filling forms, reading and understanding policy terms and conditions, and keeping track of the
        different policy rates from multiple insurance companies. This is why you'd want to get help from
        RateInsider.com
        to solve all your renters' insurance needs.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        The price of renters insurance is a crucial factor when
        shopping for the policy. However, this is not the only thing to consider. You need to learn and understand
        various
        elements to make informed decisions when shopping for renters insurance. Here are the most common frequently
        asked
        questions with answers that can help you know what to do.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="What is RateInsider.com all about?">

        <p>
          RateInsider.com works with insurance agents from various insurance
          companies that provide different insurance services to the people. Here are things you need to do to save
          money when shopping for insurance.
        </p>

        <ul>
          <li>Take a few minutes to fill our form by providing answers to the few questions in the form.</li>
          <li>We will require you to provide information about your current insurance coverage and location to connect
            you to reputable insurers within.
          </li>
          <li>Once we get all the required details, we search for insurers that offer the right policies matching your
            needs and expectations.
          </li>
          <li>While waiting for the insurance companies to contact you, you will check out quotes from multiple
            insurance companies we've identified for you.
          </li>
          <li>Since you're going to get multiple pricing structures from various companies, it'd be best to take your
            time and compare the prices before settling for the best deal. This can help you get a good deal.
          </li>
        </ul>
      </AccordionItem>

      <AccordionItem title="Does RateInsider.com provide renters insurance policies?">
        RateInsider.com is in no way an insurance company. We offer exceptional
        services in helping our customers to locate the best insurance companies, offering the best prices in renters
        insurance. All our customers who need renters insurance must liaise with our insurance partners or agents. If
        you need any help with your policy, you must contact the insurance agent or the company directly.
      </AccordionItem>

      <AccordionItem title="Does RateInsider.com offer free services?">
        All our customers enjoy 100% free services. Our main aim is to help you find
        a credible insurer offering renters insurance policies that fits your needs and expectations. You do not have
        to worry about paying us for the services. We get compensated by the insurance companies and agents as long as
        we provide quality customers.
      </AccordionItem>

      <AccordionItem title="What is the meaning of renters insurance?">
        Renters insurance is coverage for damages or theft of items that need
        repairs or replacement.
      </AccordionItem>

      <AccordionItem title="Who is the beneficiary or renters insurance?">
        A tenant with valuables they're not capable of replacing or repairing. If
        you have items that you won't be able to foot the bill when they get damaged or stolen, then renters insurance
        would be an ideal option.
      </AccordionItem>

      <AccordionItem title="How do you differentiate renters insurance from homeowners insurance?">
        Both renters and homeowners insurance have similarities in protecting
        valuables. However, homeowners insurance covers the entire home structure, while renters only cover
        belongings.
      </AccordionItem>

      <AccordionItem title="Is renters insurance an essential requirement?">
        No law requires you to have renters insurance. However, some landlords will
        ask you for it before you move in to rent or lease their houses. So, it'd be best to have renters insurance to
        be on the safe side.
      </AccordionItem>

    </Accordion>
  </Faq>
);