import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const WorkersComponsationIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        When you’re looking to purchase workers’ compensation insurance it’s not easy trying to compare dozens of
        companies from which to choose, let alone trying to determine if each quote you receive is an exact comparison
        to
        the others. With RateInsider.com, we’ll eliminate the stress by compiling all of your workers’ compensation
        insurance options for you. And we’ll leave you with a simplified process for choosing the right workers’
        compensation insurance company for your needs.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        Unfortunately, unnecessary extras are written into insurance
        packages continuously, and people end up paying for what they don’t need. RateInsider.com listens to your
        insurance needs through a series of specific questions. You’ll not only receive timely quotes and comparisons,
        but
        you’ll also save yourself hundreds, if not thousands of dollars in overpayments. RateInsider.com provides you
        with
        all the necessary tools to get through the process, and in finding the most cost-effective workers’ compensation
        insurance companies and agents available.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="What Does RateInsider.com Do?">
        <p>
          We put the power in your hands by having you first complete a few
          questions to simplify your insurance shopping experience. These questions include, in which state is your
          business? How many employees are to be covered? Who is your current provider? How much coverage are you
          seeking? And, is any additional protection coverage needed? After all of your specific answers are gathered,
          RateInsider.com does the searching and presents only those companies who can provide you with the policy
          you’re looking for. In addition, RateInsider.com will present a list of specifically chosen insurance
          providers that you can contact directly for a quote – so you don’t have to just sit and wait for our selection
          of providers.
        </p>
        <p>
          There are so many options out there, and sometimes knowing how to narrow them down can get
          a bit tricky. But we’re here to help you get the rates and comparisons you need, together in one place, to
          give you a simplified, better, and informed deciding process.
        </p>
      </AccordionItem>

      <AccordionItem title="Does RateInsider.com Provide Insurance?">
        No. We do not provide you with insurance policies, we are a referral service
        that uses our experience toward matching you with what you’re looking for. The companies we refer to you are
        insurance providers. As such, all claims, questions, or support functions must go through the provider that
        you sign up with.
      </AccordionItem>

      <AccordionItem title="How Much Will Using RateInsider.com Cost Me?">
        <p>
          Nothing. RateInsider.com is free to use. Our compensation comes from the
          workers’ compensation insurance companies that we successfully match with our users. This allows us to
          concentrate on giving you the best possible shopping experience available. We do what we do best, and then we
          proudly pass it on to you.
        </p>
        <p>
          There are so many questions to ask and answer if you are to fully understand
          the very important roles that workers' compensation insurance plays when it comes to protecting businesses and
          employers.
        </p>
      </AccordionItem>

      <AccordionItem title="What is the role of Workers’ Compensation Insurance?">
        It’s an insurance policy that covers companies and employers for any bills
        and expenses that are incurred due to an employee's injury on the job.
      </AccordionItem>

      <AccordionItem title="Which injuries does Workers’ Comp Insurance cover?">
        Most any injury that takes place on the job, whether it’s a work-related
        illness or accident, an act of violence or terrorism, or a natural disaster are among them.
      </AccordionItem>

      <AccordionItem title="What is not covered by Workers’ Comp Insurance?">
        Injuries or illnesses that are not caused by working on behalf of the
        company. They include, accidents during the commute to work, intentional harm caused by the employee,
        intoxication and/or fighting at work, assault and battery, personal emotional distresses, or defamation.
        However, it will not protect against lawsuits brought upon the employee for discrimination, malicious intent,
        failure to promote, wrongful termination, or gross negligence, etc.
      </AccordionItem>
    </Accordion>
  </Faq>
);
