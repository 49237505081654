import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const LifeRatesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      Many people are confused by the process of shopping for life insurance. What kind of insurance do you need? How
      much should you purchase? It's easy to be tricked into policies that don't meet your exact needs. If you are
      shopping for life insurance, or thinking about doing so, ask yourself the following questions. The answers should
      lead you to the policy that is perfect for you.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="1. Who needs life insurance?">
        <p>
          Most adults need life insurance. It is considered part of good financial
          planning. Bills do not stop when people die; in fact, many times even more bills are added. Your next of kin
          will need to pay for your final medical expenses, burial expenses, and estate taxes, in addition to normal
          bills. In addition, if you are currently a wage earner or head of household, your lost income may leave your
          family in a hard place. It's important to ensure that your dependents are not left destitute or in debt when you
          die.
        </p>
      </AccordionItem>

      <AccordionItem title="2. How much life insurance do I need?">
        <p>
          Conventional wisdom suggests that you should get five to eight times your
          annual income in life insurance. However, this amount may not be right for your needs. Consider the
          following:
        </p>
        <ul>
          <li>How many people depend on my income to survive?</li>
          <li>What amount of household expenses does my income cover?</li>
          <li>Do my parents or other relatives depend on financial help from me?</li>
          <li>Do I want to ensure I leave enough money for my children to attend college or other training?</li>
          <li>Who will pay for my final medical bills, burial expenses, and other end of life expenses?</li>
          <li>How many debts do I have?</li>
          <li>Will I want to leave money to charitable organizations or family members?</li>
          <li>How will inflation affect expenses in the future?</li>
          <li>Will estate taxes need to be paid on my estate?</li>
        </ul>
      </AccordionItem>

      <AccordionItem title="3. What is the right kind of life insurance?">
        <p>
          There are many different types of life insurance policy. Some cover you for
          a set term while others cover you for life. Some build cash value while others don't. However, there are two
          basic types of policy: term and permanent.
        </p>
        <p>
          Term insurance has lower premiums for young and healthy people
          but does not build up a cash value. The term, or amount of time it covers you, can vary from one year to
          decades. If you die during that time, then your beneficiaries get a payout. When the term is up, many companies
          will allow you to renew the policy, sometimes for a higher price if you are significantly older or your health
          has changed. When purchasing a term policy, you should be asked whether you will be allowed to renew.
        </p>
        <p>
          Permanent Insurance includes types such as whole life, universal life, and variable universal life. These
          policies will cover you for your entire life if you make the premium, and also build cash value. Their
          premiums are usually higher, but they remain fixed even when you age or develop health problems.
        </p>
      </AccordionItem>

      <AccordionItem title="4. Can life insurance companies deny my application due to a health problem?">

        <p>
          Many life insurance companies require a physical examination in order to
          purchase a policy. Others ask you to answer questions about your health. If you have health problems, the
          company may either deny your application or charge a higher than normal premium.
        </p>
      </AccordionItem>

      <AccordionItem title="5. Some life insurance ads claim “you cannot be turned down.” What's the catch?">
        <p>
          Some policies are called 'guaranteed issue' because they ask no health
          questions and will give insurance to anyone who applies. These are usually very expensive, as people with severe
          health problems will be more likely to use them. In some cases, the premiums are as high as the payout if you
          die. In addition, they may have fine print stating that they will only pay back the amount you have paid in
          premiums if you die soon after purchasing these.
        </p>
      </AccordionItem>

      <AccordionItem title="6. Why are some insurance agents reluctant to sell term insurance?">

        <p>
          Some agents don't like to sell term policies because they are viewed as high
          risk. If your health deteriorates or you cannot afford high premiums in the future, you may be unable to get a
          term policy when your current term ends. In addition, agents often get higher commissions and payments for
          selling permanent insurance.
        </p>
      </AccordionItem>

      <AccordionItem title="7. What do I get when I buy term insurance?">
        <p>
          When you buy term life insurance, you guarantee that your dependents will
          have the set amount of money given to them if you die during the term. There is no cash value or other side
          benefits.
        </p>
      </AccordionItem>

      <AccordionItem title="8. Does that mean I've wasted my money if I don't die?">
        <p>
          Insurance is never a waste of money. Most insurance is bought for an event
          that does not happen to us--a hospitalization, a car accident, sudden death. It is not a waste of money because
          it plans for unforeseen events and gives you peace of mind.Regardless of the type and amount of life insurance
          that you buy, you should always feel comfortable throughout the process and understand exactly what you are
          purchasing. Whatever your particular needs and budget, there is likely a plan that meets your criteria.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);