import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const MotorcycleRatesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      For the person who is looking to upgrade or change their motorcycle insurance, it is immensely important to take
      a detailed approach when evaluating the insurance options that are available. There are certain questions that
      should be answered during the process of evaluating different insurance companies and the policies that they
      offer. The following six questions represent areas where people are unsure about their coverage. The answer to
      these questions will provide the clarity necessary to make an educated decision when choosing the motorcycle
      insurance that will be right for you.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="How is insuring a motorcycle different than insuring car?">

        <h3>Risk</h3>
        <p>
          The most prevalent difference between insuring a motorcycle
          verses a car is the risk variable. There are simply different types of risks associated with riding a
          motorcycle. The unique risks associated with riding a motorcycle must be accounted for in the type of coverage
          that will be provided. These risks also impact the development of programs that reward drivers for safe
          driving.
        </p>

        <h3>Type of Vehicle</h3>
        <p>
          Other variables include the difference in the vehicles and the presence
          of safety mechanisms, or the lack thereof.
        </p>
      </AccordionItem>
      <AccordionItem title="Is it a good idea to buy collision coverage if I own an older bike?">

        <p>
          There is no one-size-fits-all answer to this question. It all depends on the
          value of the bike. Being that collision coverage only covers the current value of the bike, it does not make
          sense to purchase coverage that exceeds the actual value of the bike. However, if the bike is still being
          financed, the lien holder will require that you maintain insurance coverage on the bike for the purpose of
          protecting their investment. It will also protect you from having unsupported debt in the case of an
          accident.
        </p>
        <p>
          While dropping collision coverage will definitely lower the overall cost of your motorcycle
          insurance, it is worth noting that if there is an accident, you will be completely responsible for the repairs
          or the replacement of the bike. The ability to pay for expenses out of pocket should be balanced with
          purchases
          and low-cost collision coverage package.
        </p>
      </AccordionItem>
      <AccordionItem title="How much motorcycle insurance coverage is right for me?">

        <p>
          There are a number of different factors that must be considered when
          determining how much coverage is best in any particular situation.
        </p>
        <h3>State Requirements</h3>
        <p>
          The first
          consideration must be the minimum requirements mandated by your state of residence. Most states will require a
          minimum amount of liability coverage. It will be important to be familiar with what the minimum requirements
          are. While most insurance agents and insurance companies will be aware of this, it is always best to have a
          clear understanding yourself.
        </p>
        <h3>The Type of Motorcycle</h3>
        <p>
          Another variable that will heavily influence
          the amount of insurance that is required for your motorcycle is the type of motorcycle you are insuring. A
          good
          example would be a moped or scooter. It stands to reason that a moped does not have the capacity to cause the
          same level of damage as a full sized Harley Davidson, so the type of coverage that will be required for the
          moped will be reflective of that fact.
        </p>
      </AccordionItem>
      <AccordionItem title="What different types of motorcycles do you cover?">

        <p>
          We cover all types of motorcycles and similar vehicles, including touring
          bikes, sport bikes, scooters, ATVs and more. We also cover classic bikes, golf carts and a number of other
          types
          of specialty vehicles. There is a good chance if you want to insure it, we cover it.
        </p>
      </AccordionItem>
      <AccordionItem title="What type of discounts do you have available?">

        <p>
          We have a number of different types of discounts that can be applied to
          certain types of bikes. Additionally, we have discounts that are based on driver safety and driver record.
          Some
          options include:
        </p>
        <ul>
          <li>Discounts for safe driving</li>
          <li>Discounts for touring bikes</li>
          <li>Discounts for insuring multiple bikes</li>
        </ul>
      </AccordionItem>
      <AccordionItem title="How do I get the best possible motorcycle insurance rates?">

        <p>
          There are a number of different things that you control, as the driver,
          which will help to lower the overall cost of your insurance premiums, including:
        </p>
        <ul>
          <li>Lowering the coverage limit and selecting a higher deductible will help to lower your insurance cost.</li>
          <li>Choosing a bike that is considered safer than others will also impact the cost of your premiums</li>
          <li>Maintaining a safe driving record that is not blemished with moving violations will also positively impact
            the cost of your insurance.
          </li>
          <li>If you have more than one vehicle, whether it is another motorcycle, a car or boat, you may qualify for a
            multi-policy discount.
          </li>
        </ul>
        <p>
          It is important to understand that while having a higher deductible and lower coverage amount will lower
          insurance payments, it also means that you will be responsible for more out-of-pocket expenses in the case of
          an accident.
        </p>
        <p>The key to ensuring that you get the best policy for your motorcycle, at the best possible
          rate, begins with having a clear understanding of what you are looking for, allowing you to gather all of the
          facts. When you take this detailed approach, you will be able to find the motorcycle insurance that will be
          right for your particular situation.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);