import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const HealthQuotesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      The health insurance marketplace is difficult to navigate and an individual who doesn't have a clear idea of
      what he needs when it comes to health policies could end up buying too much insurance or not enough. It's
      important to understand what different types of health plans have to offer when comparing your choices. You want
      to make sure that you're comparing apples to apples before you lock yourself into an insurance plan for a year.
      Use our convenient quote system to help you determine which insurance plan is the right one for you.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="1. What should I consider when choosing a health plan?">

        <p>
          It's important to understand the terms associated with health insurance so
          that you know how much you'll be paying. The premium is the amount of money you pay on a monthly basis
          regardless of whether you use your insurance or not. The deductible is the amount of money you must spend
          before the insurance starts to pick up the tab. A co-pay is the amount of money you must pay whenever you
          see
          a doctor or specialist outside of regular checkups. Co-insurance is a percentage of the costs that you must
          pay, while the insurance company pays the other costs.
        </p>
        <p>
          In most cases, you'll find that there's a sort
          of balance between these terms. Plans that have high monthly premiums may have lower co-pays, deductibles,
          and
          co-insurance. Plans with low monthly payments have higher co-pays, deductibles, and co-insurance.
        </p>
        <p>
          When
          choosing a health plan, consider how much you expect to use the insurance and estimate the cost to you. For
          example, a fairly healthy person who only goes in for annual checkups may do fine with a low-premium,
          high-deductible plan. Someone who has a diagnosed illness that requires monitoring or medication may find
          that
          it's more affordable to pay the higher monthly premiums for better coverage.</p> <p>If you currently have a
        doctor you prefer, you may want to check each insurance plan to see if the doctor will accept that type of
        coverage. Many doctors accept a variety of insurance plans, but occasionally, a doctor is part of a limited
        network.
      </p>
      </AccordionItem>

      <AccordionItem title="2. Can I purchase insurance outside of open enrollment?">

        <p>
          The open enrollment period is a time when people can freely make changes to
          their health insurance plans. Limiting the times when consumers can make changes helps the company to
          control
          costs.
        </p>
        <p>
          However, there are times when you are able to make changes to your insurance plan outside of the
          open enrollment period. For example, if your employer was covering your health plan and you recently lost
          your
          job, you can select a new type of insurance. You can also sometimes make changes for other types of life
          changes, such as birth, death, marriage or divorce, moving to a new area outside of the network, or a
          significant change in your financial situation.
        </p>
      </AccordionItem>

      <AccordionItem title="3. Am I covered outside of the service area and outside of the country?">

        <p>
          Exact policies vary depending on the insurance company and plan you choose. If
          you expect to travel outside of your local area frequently, this is an important consideration.
        </p>
        <p>
          Most
          insurance plans offer some amount of coverage for patients seen 'outside of the network,' which can include
          people who are traveling abroad. However, the amounts covered for this care may be less than the insurance
          would usually cover for patients being seen within the network. If you plan to travel within the United
          States, it can be smart to select an insurance plan that has a nationwide network.
        </p>
      </AccordionItem>

      <AccordionItem title="4. Is there a contract or can I cancel at any time?">

        <p>
          In most cases, your insurance plan is an annual contract, and you are required
          to keep the plan for the year. If you cancel your insurance plan, and you haven't had any life events that
          would naturally allow you to change the insurance plan, you may be unable to purchase a new insurance plan.
          This lack of insurance can be costly if you need health care, and it can also lead to a tax penalty.
        </p>
      </AccordionItem>

      <AccordionItem title="5. How much does a health insurance plan cost?">

        <p>There are many variables that determine how much your health insurance plan
          will cost, such as your age, where you live and the type of coverage the plan offers. A bare-bones plan with
          a
          high deductible can cost just a few hundred dollars a month, but plans that offer a lot more coverage can be
          a
          lot more expensive. The only way to get exact numbers is to request a few quotes from several different
          companies. This allows you to see how much different plans cost and compare your options side-by-side. It's
          sometimes helps
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);