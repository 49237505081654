import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const LifeQuotesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      If you have life insurance when you die, those you leave behind will receive money that they can use to cover
      important costs that you would have otherwise been responsible for. Unfortunately, shopping for life insurance can
      be overwhelming at times. Many consumers don't understand the different types of insurance plans available and
      they may be sold a policy that is either too big or too small for their needs. Educating yourself about what's out
      there is the first step to purchasing a quality plan that's right for you.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="When is the right time to buy a term life policy?">
        <p>
          Term life insurance has a specific start and end point. You'd typically buy
          it in 15-, 20-, or 30-year increments. There are lower premiums for term life policies when compared to
          permanent life insurance policies. The lower premiums make them an attractive option for those who are unable to
          fit high premiums into their budget. A term policy is also ideal when you're thinking about wanting to have
          money to cover a specific cost should you die. For example, you might purchase enough coverage to pay for your
          child's college education in the event of your death. Then, when the term of the policy is over, your child will
          have hopefully already graduated and you can purchase a different type of policy.
        </p>
      </AccordionItem>

      <AccordionItem title="What's the difference between term life insurance and permanent life insurance?">
        <p>
          Term life insurance plans feature lower premiums, but you'll have to renew
          the policy at the end of the term. Since younger -- and presumably healthier -- people get better rates, your
          rates might increase at the end of the term. There may not be as much fluctuation in the premiums paid for
          permanent plans, but the premiums will definitely be higher. Part of the reason that the premiums are higher is
          that permanent life insurance plans can build a cash value. Once this has built up, you can then use that money
          to take out a loan. Term insurance plans do not build up a cash value.
        </p>
      </AccordionItem>

      <AccordionItem title="What are the kinds of term life insurance that you can purchase?">
        <p>
          Level term insurance, decreasing term insurance and annual renewable term
          insurance are the three most common types. Level term insurance is probably most popular, as it's
          straightforward. The benefit amount and the monthly premium remain the same throughout the term of the policy.
          Like the term implies, the benefit amount in a decreasing policy decreases over time, though the premium usually
          remains the same. This might be a good choice for someone who has a financial obligation that will end in the
          future, such as paying off a mortgage or school loans. In the annual renewable policy, the benefit amount will
          remain the same, but the premiums will increase from year to year.
        </p>
      </AccordionItem>

      <AccordionItem title="When is term life insurance 'renewable'?">
        <p>
          At the end of your policy's term, you'll likely be given the option to renew
          the policy. In some cases, the policy might automatically renew. However, the premium you'll now pay should be
          higher than the one from the original term. Additionally, many policies have an age limit and if you reach that
          age limit, you are unable to continue holding the policy.
        </p>
      </AccordionItem>

      <AccordionItem title="What does a 'convertible' term life insurance policy mean?">
        <p>
          A customer who buys a convertible policy has the option of converting from
          term life insurance to permanent life insurance within a given time frame, usually a few years after starting
          the policy. One of the advantages of this type of policy is you'll qualify for the permanent insurance plan
          without having to re-take the physical exam. All life insurance policies require an initial physical exam that
          helps determine the premium rates.
        </p>
      </AccordionItem>

      <AccordionItem title="How long does a term policy last?">
        <p>
          It depends on the terms written in the contract. When you purchase a level
          term insurance plan, the quotes you receive will be for specific terms, such as 10 or 30 years. Other types of
          plans may have different terms. For example, with the annual renewable term insurance plan, you'll have to renew
          your policy every year. It's smart to purchase a policy that's as long as you need it because you never know how
          premium rates can change when you need to renew the policy.
        </p>
      </AccordionItem>

      <AccordionItem title="Do term life insurance premiums change over time or remain steady?">
        <p>
          Again, this depends on the type of policy you purchase. If you purchase a
          level term insurance policy, you can expect your premiums to stay the same throughout the term of the policy.
          This is extremely convenient when it comes to budgeting. Other policies might have premiums that gradually
          increase over time.
        </p>
        <p>
          Policies that have 'indeterminate' premiums will vary the premium from year to year,
          but the company will set a 'maximum premium' that you'll have to pay. In any given year, your actual premium is
          more likely to reflect the current rate rather than coming close to being the maximum rate. However, it's smart
          to ask the company to see what the possible maximum rate could be.
        </p>
      </AccordionItem>

      <AccordionItem title="Is it possible for the company to cancel the term insurance plan?">
        <p>
          In general, the only reason that a company can cancel your insurance policy
          is due to non-payment of the premiums. If you miss a payment and fail to catch up, the company will cancel your
          policy. You usually have a 31 day grace period. If your life insurance plan is through your employer's group
          plan, it's possible that the insurance company would cancel your policy if you cease to be employed by that
          employer.
        </p>
        <p>
          Fortunately, insurance companies cannot cancel policies if your health takes a turn for the
          worse. Should you become seriously ill, such as being diagnosed with a disease like cancer, the company will
          have to continue your policy as set forth in the original terms. When the term ends, though, you can expect your
          premiums to rise due to your declining health.
        </p>
      </AccordionItem>

      <AccordionItem title="What is the 'Return of Premium' feature on a life insurance policy?">
        <p>
          In most cases with term life insurance, the premiums you pay stay with the
          company. Some policies, though, have a clause for returning all or part of the premiums you've paid. If you
          reach the end of the term without needing to use the policy, the company will pay you back those premiums.
          However, the monthly premium for a policy that has this feature is likely to be significantly higher than the
          premium for a policy without it. It's smart to consider whether the amount you'll get back in premiums is worth
          the extra upfront costs as you start to pay your mortgage premiums.
        </p>
      </AccordionItem>

      <AccordionItem title="How often do I have to pay my insurance premiums?">
        <p>
          Most insurance companies give you the option of paying annually, quarterly,
          bi-monthly or monthly. In some cases, there is a small financial incentive for paying a year's worth of the
          premium upfront, and it's easier to not have to remember to make a monthly payment. However, if you choose to
          make an annual payment, you may not be able to receive a portion of that premium back should you decide to
          cancel the policy before the year is over. Also, when you pay annually, you have to remember that you'll have to
          make that large payment again in a year. Many people find monthly or bi-monthly payments to be convenient and
          affordable.
        </p>
        <p>
          No matter what type of life insurance policy you choose to purchase, it's important for you to
          have a good understanding of what the policy entails. Take the time to do your research. Look for a caring
          broker who's willing to educate you on the things you don't understand. When you do this, you'll feel a lot more
          confident about the purchase you're making.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);