import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const HomeRatesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      When you are shopping for home insurance, it is important to have a complete understanding of all the issues
      surrounding your policies, coverage and options. When all of your questions regarding home insurance coverage are
      answered, you can make the best-informed decision possible. By exploring the most frequently asked questions for
      home insurance policies listed below, you can give your family and investments the kind of protection that they
      deserve.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="1. What items inside my home are covered by my policy?">
        <p>
          The vast majority of items in the home are covered by standard policies.
          Disasters and other incidents such theft and fire will protect the possessions in your home. Still, there are
          some limits to coverage for certain items that are defined in your policy. For instance, guns, securities,
          money, and certain types of recreational vehicles have limits to their coverage. Additionally, there are small
          items that cannot be claimed under some circumstances. The loss or damage of a bracelet or adornment would not
          be covered, for example. Coverage of this kind requires that particular items be scheduled and specifically
          insured at the beginning of the policy.
        </p>
      </AccordionItem>

      <AccordionItem title="2. Can special items in the home be covered by my policy?">
        <p>
          A considerable number of valuables and specialty items are covered by
          standard policies. For example, silverware and other valuables in the home that are cited in the policy will
          be
          covered up to a certain limit. The standard loss due to theft for items mentioned above can range between
          $1,000
          and $2,500. The dollar amounts can also be raised when items are scheduled under the policy beforehand.
          “Scheduling” an item is the basic process used to extend or broaden coverage for specialized items. Additional
          possessions that are typically scheduled include antiques, electronic equipment, and photographic equipment.
          The
          policy holder must describe the items specifically, insure them individually, and pay an additional premium in
          some cases based on the amount of coverage.
        </p>
      </AccordionItem>


      <AccordionItem title="3. Is there coverage related to vandalism or malicious acts?">
        <p>
          Coverage for these concerns is always in place up to your policy limits.
          However, coverage may be suspended if the home was vacant for 30 or more days prior to the incident
          occurring.
        </p>
      </AccordionItem>


      <AccordionItem title="4. If someone is injured on my property, then am I still protected?">
        <p>
          The vast majority of policies do not provide coverage in this respect.
          Addressing this concern requires evaluating the specifics related to liability policies that come with
          coverage.
          When arranged to provide this kind of protection, you can receive coverage that includes any injury to
          neighbors
          or visitors for whom you are legally responsible.
        </p>
      </AccordionItem>


      <AccordionItem title="5. Will living expenses be covered during any necessary repairs if my home is damaged?">
        <p>
          If the home is damaged by an event that is covered, then Loss of Use
          Coverage can be put in place. This benefit provides you with “Additional Living Costs” that typically exceed
          the
          normal costs of living. On average, the payout translates to 20 percent of the coverage amount on the home.
        </p>

        <p>
          With these questions answered, you are one step closer to having all the information you need to be sure that
          the policy is just right for you.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);