import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const MotorcycleIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        There's no doubt that shopping for motorcycle insurance can be an overwhelming and daunting experience. This
        becomes especially apparent once you begin the tedious process of filling out endless forms, comparing policies,
        and recording multitudes of quotes between different providers. Fortunately, RateInsider.com is designed to
        dramatically simplify this process so that you can get back to the more important things in life.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        Every day,
        millions of people unknowingly hand over unnecessarily large amounts of money to insurance companies for
        services
        they don't even need. At RateInsider.com, you can easily save money and avoid superfluous fees and costs through
        a
        simple foolproof, step-by-step process. RateInsider.com will ask you extremely simple questions about yourself
        and
        the type of policy you're looking for, and from there, you'll immediately be connected to agents who can offer
        you
        personalized insurance packages that meet your needs.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        It's never been easier to shop for motorcycle insurance
        than it is with RateInsider.com. After a few short moments, you'll have access to plenty of specialized quotes
        that
        fit your budget and needs.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        Before getting started, we'll take a few moments to help you understand the basics
        of RateInsider.com. These questions will not only help you understand our service, but you'll also gain a better
        understanding of exactly how motorcycle insurance, itself, works. This way, you can make more informed decisions
        about your insurance offers.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="What exactly does RateInsider.com Do? insurance?">
        <p>RateInsider.com is designed to connect people to insurance companies. Our
          network is comprised of thousands of agents who are all equipped to handle different types of needs and
          budgets.
          Our process consists of five simple steps for connecting people with our network of insurance companies.</p>
        <ol>
          <li>You complete our simple and short online form. Don't worry; each question we'll ask will be clear and easy
            to understand. Our goal is to make the quote retrieval and comparison process as simple as possible for you.
          </li>
          <li>You tell us what you're looking for. The more information you provide about the type of policy you desire,
            the better recommendations we'll be able to give you. Be as specific as possible about your location,
            desired coverage, driving habits, and current policy.
          </li>
          <li>We find perfect matches for you. Based on the information you provide, we locate the insurance companies
            and agents who can best suit your needs.
          </li>
          <li>You receive our list of chosen providers. If you'd like faster quotes while you wait, you can use the list
            of pre-selected providers on the screen.
          </li>
          <li>You compare quotes. We'll deliver our results within a short period of time, and from there, you can
            narrow your decision down to a single company. Be sure to check out each of our recommendations, as they
            were made specifically for you!
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Does RateInsider operate as an insurance company?">
        <p>
          Here at RateInsider.com, we do not classify ourselves as an insurance
          company. Think of us as the helping hand that guides your way to the best insurance companies available. When
          you purchase an insurance policy using RateInsider.com, you are essentially purchasing it from the insurance
          agent or company that you chose from our recommendations. If you have questions, concerns, or claims that need
          to be addressed, you should contact the insurance company through which you purchased insurance coverage.
        </p>
      </AccordionItem>
      <AccordionItem title="Is it free to use RateInsider.com?">
        <p>
          One of the reasons many people turn to RateInsider.com is because it's
          completely and 100% free to use. We simply glean pride from being able to help countless people find quality
          insurance every day. We are able to keep our service running via compensation from insurance companies for our
          referrals.
        </p>
      </AccordionItem>
      <AccordionItem title="What exactly is motorcycle insurance?">
        <p>
          In the event that you are involved in a motorcycle accident, theft, loss, or
          damage, motorcycle insurance can protect you from the associated financial losses. Insurance companies
          commonly
          cover bikes such as motorcycles, street bikes, 'crotch rockets,' choppers, and mopeds. Motorcycle insurance
          can
          even be used to cover Segways.
        </p>
        <p>
          Whether you're a new owner to a motorcycle or you're an existing owner
          looking to change insurance companies, you likely have many unresolved questions. You might wonder how
          coverage
          works, or what you might be legally responsible to pay for if you're in an accident. Speak to a local
          professional in law or insurance to help you understand specific regulations for your state.
        </p>
      </AccordionItem>
      <AccordionItem title="What is covered by motorcycle insurance?">
        <p>
          When your motorcycle is covered by insurance, you can rest assured that, in
          the event of a mishap, at least a portion of the damages will be financially covered. If you happen to hurt
          another individual, damage their property, or accrue legal fees from an accident, this will also be
          compensated.
          With “bodily injury and property damage liability' coverage, you are covered in the event that you're involved
          in an at-fault accident and presented with a liability claim and/or legal costs. This coverage extends to your
          policy's limits.
        </p>
        <p>
          Any insurance policy can be customized. You can add coverage for the following if you wish:
        </p>
        <ul>
          <li>A complete motorcycle replacement (if totaled).</li>
          <li>Full coverage (comprehensive).</li>
          <li>Medical treatment.</li>
          <li>Coverage for accidents involving underinsured/uninsured drivers.</li>
          <li>Damage/loss of upgrades/gear.</li>
        </ul>
        <p>
          Of course, the tug of war in an insurance policy will always come between coverage and budget. There are
          many ways to save money while still retaining a sufficient amount of coverage for your motorcycle.
        </p>
      </AccordionItem>
      <AccordionItem title="What type of coverage do I need for motorcycle insurance?">
        <p>
          The coverage type you need will be heavily impacted by your bike and what
          you plan to do with it. Racing bikes, super sport motorcycles, and crotch rockets are all more expensive to
          cover. With these types of bikes, it is often wise to increase your liability coverage along with your medical
          coverage.
        </p>
        <p>
          Bikes that are designed with maneuverability and speed in mind are often intended for fast
          riders. The chances are higher that these riders will weave between traffic and go faster, and therefore, the
          risk of an accident or injury is higher. Additionally, those who race dirt bikes in their free time should
          think
          about purchasing more coverage.
        </p>
        <p>
          Because states have different requirements pertaining to motorcycle
          insurance, it's best that you work with a local agent who knows the laws and can create a package that abides
          by
          them and protects you in the process.
        </p>
      </AccordionItem>
      <AccordionItem title="How does insurance for motorcycles work?">
        <p>
          If you own a car, then you are already familiar with how motorcycle
          insurance works. It works in a much similar fashion.
        </p>
        <p>
          The coverage terms inside of your motorcycle
          insurance policy are designed to cover various costs if your bike ever suffers damage, loss, or theft. For
          example, liability coverage is used to cover the costs of harm to another person or their property. Keep in
          mind
          that the coverage can only pay for costs within its limits. If you cause $35,000 in damages to another
          person's
          property but your policy only covers up to $20,000 in damages, you will be required to finance the remaining
          $15,000 out of pocket.
        </p>
        <p>
          Liability can also be used to protect you from legal costs. This is useful if
          you're involved in an accident with another person and he/she chooses to sue you for damages. If the legal
          costs
          exceed what is covered by your policy, you will also need to individually pay the remaining amount.
        </p>
        <p>
          If
          you're involved in an accident that causes bodily harm to you, your insurance policy might compensate a
          certain
          amount of your medical costs. Additionally, coverage for an accident involving an underinsured/uninsured
          driver
          will help you cover costs that the other party can't pay.
        </p>
        <p>
          Comprehensive (full) coverage is used for
          covering damages/losses that did not originate from a collision. For instance, you would be covered in the
          event
          of a theft, weather catastrophe, and so on.
        </p>
        <p>
          Before the insurance company will pay for any costs associated
          with an accident or other mishap, you will likely be required to pay a deductible. A deductible is a
          predetermined amount of money that you need to pay before your insurance officially kicks in and provides
          coverage. For example, if your bike suffers $5,000 in damages and your policy has a $500 deductible, you will
          be
          required to pay that $500 before the insurance company pays for the remaining $4,500.
        </p>
        <p>
          Fortunately, you
          can adjust your deductible to suit your needs. If you'd like a lower deductible, your premium will
          consequently
          increase. A higher deductible will cause your premium to lower.
        </p>
        <p>
          A local agent will be able to help you
          understand the types of coverage that are most important for you and your driving habits.
        </p>
      </AccordionItem>
      <AccordionItem title="Why is it important to have motorcycle insurance?">
        <p>
          Motorcycle insurance, quite simply, is a layer of protection for not only
          you, but others around you. Insurance for your motorcycle is particularly important for a number of reasons:
        </p>
        <ul>
          <li>They're harder to spot on the roads. Because of this, they're much easier to hit.</li>
          <li>Their maneuverability and speed makes them difficult to spot.</li>
          <li>They're more susceptible to crashes due to their usage of only two wheels.</li>
          <li>They're more dangerous than cars due to their lack of air bags or seat belts.</li>
        </ul>
        <p>
          This is why it's extremely important that any motorcyclist seek solid coverage. Without financial protection,
          you are susceptible to paying tens, or even hundreds of thousands out of pocket for damages and medical costs.
          Insurance is not something to shy away from; we can help you find a policy that works for you and your
          budget.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);