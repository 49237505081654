import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const MedicareQuotesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      Knowing how to sign up for Medicare is just the tip of the iceberg. You also need to know what the insurance
      covers as well as what you are qualified for. These Frequently Asked Questions will help you learn more about the
      path that is best for you. You might find that you already qualify for Medicare, or that your current insurance
      plan is working better for you. Look to these answers for solutions to your insurance dilemmas.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="How do I sign up for Medicare?">

        <p>
          Signing up for Part A and Part B is automatic for most people. This is
          especially the case if you are already receiving benefits, like Social Security or Railroad Retirement Board,
          when you turn 65. If you are under the age of 65 and have a disability, you are also automatically signed up
          for
          Part A and Part B Medicare. Additionally, people who have been diagnosed with ALS are also signed up. You do
          not
          have to do anything to sign up in most cases. Of course, you may be caught in a snag where you do not receive
          information regarding Medicare when you become disabled or turn 65. In this case, it is time to call the
          Social
          Security Administration for additional information.
        </p>
        <p>
          If you are not in one of these scenarios where you are
          automatically enrolled in the Medicare program, you will get in touch with the Social Security Administration
          for more information. You may be asked to submit forms, including proof of age. You may also be asked to
          provide
          information regarding your disability and working status. It all depends on your specific situation. As you
          fill
          out forms, ensure that you are honest.
        </p>
      </AccordionItem>
      <AccordionItem title="What is a Medicare Advantage Plan?">

        <p>
          You may travel out of state often, and you must find a plan that will cover
          you when you are out of state. The Medicare Advantage Plan is recognized by most hospitals and medical clinics
          around the country, and you will be covered when you visit these locations. It is a rare location that does
          not
          accept your Medicare Advantage Plan when you arrive.
        </p>
      </AccordionItem>
      <AccordionItem title="How much does Medicare cost?">

        <p>
          Supplementing Medicare is important when you go on public insurance, and
          there are several plans that will help you remain covered completely. There are gaps in standard Medicare
          plans,
          and the Medicare Advantage Plan will supplement your insurance to give you full coverage. You will not be
          asked
          to pay a cent when you receive medical care, and your supplemental insurance will help cover you whether you
          are
          in or out of state.
        </p>
      </AccordionItem>
      <AccordionItem title="What is the late enrollment fee?">

        <p>
          The late enrollment fee for a Medicare Advantage Plan is relatively low, but
          you will be asked to pay a fee when you are late enrolling. There is an open enrollment period every year, and
          you must sign up for your insurance during that period. Any late enrollments are assessed a fee, but you will
          still receive your coverage. People who come to the program late are allowed to choose a plan, but there is an
          extra fee added to the price of the policy.
        </p>
      </AccordionItem>
      <AccordionItem title="Why do you need supplemental insurance?">

        <p>
          Supplemental insurance is a common item in the world of Medicare, and most
          seniors carry supplemental insurance to prevent surprise charges after receiving care. A medical clinic or
          hospital has every bill covered by the Medicare or Medicare Advantage Plan. You will receive a bill with not
          patient responsibility, and you can be sure you are covered in nearly every location you visit. You must
          ensure
          that you have supplemental insurance when you travel often, and you must understand how much coverage is
          offered
          at the locations near your home.
        </p>
        <p>
          Supplemental insurance is necessary for all Medicare recipients who are
          on a fixed income. You do not have money to spend on more medical bills, and a Medicare Advantage Plan helps
          you
          prevent problems with your billing after receiving treatment. Advantage Plans are accepted in most medical
          offices around the country, and the plans you choose will help you cover items that are not handled by
          Medicare.
          Take your own health into account when choosing a Medicare Advantage Plan, and ensure that you are purchasing
          a
          plan that fills in the gaps. Your health and livelihood depend on good health insurance.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);