import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const LifeIndexFaq = () => (
  <Faq hidePretext={false} preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        You have seen the commercials. You have heard the claims. Getting the right policy can be confusing and
        downright
        frustrating. There are legal terms that insurance companies expect you to know. Then there are the confusing
        rates
        for various types of insurance. It can be enough to drive you absolutely batty over the details. Life insurance
        rates vary due to a number of personal factors. For example, a six-pack a day smoker will be quoted a higher
        price
        than a non-smoker. Age and marital status often play a role in what type of life insurance policy you'll
        receive.
        Single people often get quoted for less comprehensive plans than married couples. Factors like a pre-existing
        condition such as cancer can cost consumers thousands of dollars in fees and a raise in rates. Location and area
        of the country quite often prove to be a factor in how much insurance someone receives and pays out of pocket.
      </p>

      <p className="text-base text-gray-700 md:text-lg">
        Rateinsider.com compares the top rated life insurance companies by location, coverage, marital status and health
        status. Most insurance companies decide what to charge you for premiums based on ability to pay and health
        status.
        Depending on what type of insurance you get, whether it is permanent (whole life) insurance or term life
        insurance, the rates will increase or decrease according to your age, financial status and health status.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="Why should I get life insurance?">
        <p>
          Life insurance primarily pays final expenses. Everyone should have at least
          one policy to cover funeral costs, cremation or burial costs and other final expenses. It is also prudent to
          have life insurance that pays final hospital and medical bills, along with any credit card balances. Some life
          insurance policies also pay beneficiaries money for housing and other expenses for a specific amount of time.
          These expenses can be extensive, creating an undue burden on the family.
        </p>
      </AccordionItem>

      <AccordionItem title="How do I know how much life insurance to get?">
        <p>
          This all depends on what expected expenses you think you will incur and what
          you feel comfortable enough to pay for coverage. It also depends on your dependents and spouse. Life insurance
          policies contain riders based on certain conditions. For example, if you know that you want your beneficiaries
          to receive additional money if you're involved in a fatal accident, then that will require an additional fee
          for
          that coverage. Permanent (whole life) insurance policies have a set amount of coverage, which can be as low as
          $15,000 or as high as a million or more. This is why you have to figure out for yourself what coverage you
          need
          to meet your final expenses and support your family when you're gone.
        </p>
      </AccordionItem>

      <AccordionItem title="What should I look for when searching for the right insurance company?">
        <p>
          The short answer is a reputable insurance company that has been in business
          for years. You should feel comfortable enough with the reputation of the company to serve your claim.
          Rateinsider.com can help you by referring insurance companies that have a proven track record of resolving
          claims and fit your insurance needs.
        </p>
      </AccordionItem>

      <AccordionItem title="What are the advantages of a term life insurance vs. cash value (whole life)
        insurance?">
        <p>
          The initial cost of a term life insurance policy is lower than a traditional
          cash value (whole life) insurance policy. Term life insurance can be used to pay for unexpected expenses over
          the life of the policy. Term life insurance can be converted to cash value (whole life) insurance after the
          policy expires. You can customize term life insurance to cover needs during a specific length of time or
          decrease coverage as your needs change.
        </p>
      </AccordionItem>

      <AccordionItem title="Can I afford a term life insurance policy?">
        <p>
          Term life insurance policies are designed to be affordable and provide
          maximum coverage for you and your family for a set period of time. This can be determined by how many people
          you
          want to cover in the family, what period of time you want to be covered in the policy and what amount of
          coverage you need. Some term life insurance policies have a set amount of coverage per period of coverage,
          while
          others offer a simplified version of selection that is custom tailored to your specific needs.
        </p>
      </AccordionItem>

      <AccordionItem title="What conditions and attributes should I look for in a term life policy?">
        <p>
          Term life insurance policies should spell out what conditions they cover,
          what amount they will pay out to your family, if you can 'tap out' the policy during the period of the policy
          and the time period of the policy. Other factors to look for include any exclusions or exceptions to the
          payout
          rules. Some term life insurance policies clearly state when they won't pay out the claim. You also want a term
          life insurance policy that is flexible as your needs change over time. Depending on your situation, you may
          only
          want to invest in a 10 year term life insurance policy which you can switch to either a cash value (whole
          life)
          policy or reinvest in a 20 or 30 year term life insurance policy.
        </p>
      </AccordionItem>

      <AccordionItem title="How do I decide the amount and time limit of term life insurance I need for my
        policy?">
        <p>
          It is prudent to first ask yourself whether or not you actually need to
          invest in a term life insurance policy. In some cases, it might be more prudent to go ahead and invest in a
          cash
          value (whole life) insurance policy. Life changes like marriage, children and divorce often are determining
          factors for getting a term life insurance policy. 10 year term life insurance policies are practical for
          individuals and young families that don't have a lot to invest but want the maximum coverage. 20 year term
          life
          insurance policies often are used by investors that want to grow their money over a 20 year period without
          risk.
          The 30 year term life insurance policy is the highest term life insurance policy you can receive, covering a
          30
          year period of time. Investors who want the maximum amount of insurance money and/or have a long period of
          time
          to live often take out 30 year term life insurance policies.
        </p>
      </AccordionItem>

      <AccordionItem title="How often do I need to review my life insurance policy?">
        <p>
          Some insurance companies suggest that you review your policy every year or
          so. Other insurance companies require that you review your insurance policy at least every three years or so.
          Term life insurance policies, especially the 10 and 20 year policies, should be reviewed every two years. Life
          changes such as marriage, unexpected illness, accidents and divorce should trigger a good review of what
          coverage you do have with your policy. Whole life insurance policies don't need to be reviewed as often as
          they
          cover your whole life and are not determined by changes in your situation. You should, however, review whole
          life insurance policies periodically to check for changes and to update information about you and your
          family.
        </p>
      </AccordionItem>

      <AccordionItem title="What costs does a term life insurance policy cover?">
        <p>
          The basic term life insurance policy covers death costs from any cause in
          any place, except for suicide within the first two years of the policy. Some insurance companies limit this
          clause to one year. Death by pursuing risky hobbies such as skydiving is sometimes not covered completely by
          insurance companies. Serious illnesses can also bump up the premium and be contested by insurance companies as
          an unwanted risk. Some term insurance policies may not over certain situations. You should read and go over
          your
          term life insurance policy carefully with an advisor to decide which coverage is best for your situation.
        </p>
      </AccordionItem>

      <AccordionItem title="Who can be named as my beneficiaries?">
        <p>
          Only individuals that you are financially responsible for and/or family
          members should be on your beneficiaries list. Family friends and other relations will have difficulty
          receiving
          money from your estate unless they can prove that you were financially responsible for them. Charities often
          are
          the best solution when you have no one you are financially responsible for supporting.
        </p>
      </AccordionItem>

      <AccordionItem title="How can Rateinsider.com work for me?">
        <p>
          Rateinsider.com takes the guesswork out of finding the right insurance
          provider for you. They do this by working with thousands of top rated insurance companies and showing you the
          best rates available for the coverage you need. You just need to:
        </p>
        <ol>
          <li>Fill out our simple online form</li>
          <li>Provide us with basic information such as location, type of coverage you need and how much coverage you
            are looking for
          </li>
          <li>Be patient as we search through our database to match you with the best insurance providers in your
            area.
          </li>
          <li>Rateinsider.com has a list of insurance providers that you can speak directly to one on one.</li>
          <li>Compare prices and additional riders that may affect the cost of your life insurance.</li>
        </ol>
      </AccordionItem>

      <AccordionItem title="Isn't Rateinsider.com an insurance company?">
        <p>
          No. Rateinsider.com is a referral service designed to help you search out
          the best life insurance policy for your needs. You are responsible for making the necessary connections with
          the
          insurance provider you choose to purchase your life insurance. Rateinsider.com does provide a cost analysis of
          common term life and whole life insurance policies from reputable insurance companies in your area.
        </p>
      </AccordionItem>

      <AccordionItem title="How much does it cost to use Rateinsider.com services?">
        <p>
          Since Rateinsider.com is a referral site only, there is no fee for the cost
          analysis. Rateinsider.com relies on the insurance companies and agents we refer customers to in order to
          receive
          compensation for our cost analysis and referral services.
        </p>
        <p>
          It can be a difficult decision to choose between
          term life and whole life insurance. Rateinsider.com takes the guesswork out of deciding what insurance company
          provides the best policies for your needs. Term life insurance is good when you need to free up your money
          after
          a certain term to pay for mortgages and other expenses. You can also tap out money at any time during the term
          for unexpected expenses. The downturn of term life insurance is that once your term expires, your premium
          could
          increase exponentially due to a number of factors including age and health status.
        </p>
        <p>
          Rateinsider.com can
          help you compare rates for common denominators such as age, location, financial status and health status.
          Health
          status is one of the biggest factors for determining what coverage you will be quoted. Financial status and
          location often figure into the equation in regards to life insurance quotes. Where you live often determines
          what you will be willing to pay for premiums and life expectancy.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);