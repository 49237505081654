import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const RentersRatesFaq = () => (
  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        Renters insurance is a significant investment that you should maintain well for future benefits. However,
        the policy can be challenging to understand. This is why you need to familiarize yourself with the entire process
        of how the insurance works before heading to the insurance company to buy the policy. Do not make mistakes that
        can frustrate you in the future.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        With RateInsider.com, you won't have to go through the hassle of checking
        out multiple policy quotes. These questions can help you save time and money in your research.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="Is it possible to share renters insurance with a roommate?">
        Yes, you can. Your roommate can be a beneficiary of your renter's insurance.
        However, it is good to analyze the available options and the consequences before including your roommate in
        the policy. This is because you are likely going to hit your coverage limits faster. It is good to advise your
        roommate to take renters insurance because it is affordable and will protect their belongings by paying for
        repairs and replacements.
      </AccordionItem>

      <AccordionItem title="What doesn't the renters insurance offer?">
        Renters insurance does not cover damages incurred from natural disasters
        such as floods, tornadoes, and earthquakes.
      </AccordionItem>

      <AccordionItem title="Is jewelry covered under renters insurance?">
        Renters insurance covers jewelry and various electronics, but it comes with
        coverage limitations.
      </AccordionItem>

      <AccordionItem title="Are pets covered by renters insurance?">
        Some insurers cover for pet injuries on the renter's insurance. However, it
        is imperative to check the policy terms to be sure of this.
      </AccordionItem>

      <AccordionItem title="Are bed bugs covered by renters insurance?">
        Renters insurance does not cover any pests, including bedbugs. However, you
        can buy bed bug insurance as a separate policy.
      </AccordionItem>
    </Accordion>
  </Faq>
);