import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const CarQuotesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      When you are shopping for car insurance you need to know what sort of insurance you are looking for. Many people
      are tricked into policies that they do not need because they are not aware of what they want. Before you go
      shopping for your insurance, you should consider the five questions below. Answer each of the five questions
      before you buy your policy, and the answers will point you in the direction of the proper policy.
    </p>
  )}>
    <Accordion>

      <AccordionItem title="1. How Much Coverage Do You Need?">
        <p>
          When you are shopping for coverage, you cannot get coverage for the car that
          is higher than the value of the car. Many people will get a policy that can cover the replacement of the car,
          but the insurance company is only going to pay the current value of the car. If you are paying off your car, you
          will need to invest in gap insurance.
        </p>
        <p>
          Gap insurance is going to do just what it says. The gap insurance
          covers the gap between the price that the insurance company will pay to replace the car and what you owe on the
          car. You do not want to left with a totalled car that you are still making some payments on.
        </p>
      </AccordionItem>


      <AccordionItem title="2. What Does My State Require?">
        <p>
          Most states simply require you to have an insurance policy when you are
          driving. However, you should check with the state to make sure what they require. If you simply need insurance,
          you can get away with a cheap policy that will pay for repairs, but you might need to get something that is a
          little more expensive if your state has different requirements.
        </p>
      </AccordionItem>

      <AccordionItem title="3. Should I Get Comprehensive Or Collision Coverage?">
        <p>
          When you are choosing between comprehensive and collision coverage, you need
          to understand their meaning. A collision policy is going to pay for repairs to your car and the other car in an
          accident. However, these policies do not do anything else. They are very cheap because they tend to have high
          deductibles, and they do not offer much coverage. In fact, collision policies are going to only allow you to get
          repairs up to a certain value. You might not be able to get the car completely repaired, but you will be able to
          fix the car so that you can drive it.
        </p>
        <p>
          A comprehensive policy is much more comprehensive as the name
          suggests. Your comprehensive policy is going to pay to replace the car if it is totalled, and the policy will
          also pay to fix the car to its original condition if it is wrecked. However, these policies can do much
          more.
        </p>
        <p>
          A comprehensive will allow you to get a rental car if you need one. The insurance company often
          works with a rental car company, and they might even have a service centre where you take the car, where you get
          a rental and where you can return the rental car or pick up your own car.
        </p>
        <p>
          Comprehensive policies can also
          make allowances for the personal items that were in the vehicle. You can get an allowance to replace lost or
          damaged personal items, and you can work out those arrangements with the insurance adjuster by showing them to
          broken items. In essence, a comprehensive is more expensive because it does more things for you. However, it is
          more helpful when you cannot afford to have your life come to a halt after an accident.
        </p>
      </AccordionItem>

      <AccordionItem title="4. What deductible should I select?">
        <p>
          When you are selecting a deductible for your insurance policy, you are
          weighing the cost of the policy against the amount of money you will have to pay when you need to meet your
          deductible. If the insurance company is paying $5000 in repairs, you will pay your deductible before the
          insurance company covers the repairs.
        </p>
        <p>
          Most people have policies with higher deductibles so that they will
          only have to make small monthly payments. However, you may want to make a larger payment every month so that you
          can lower your deductible. The best policies are ones that have no deductible. You pay a higher price per month,
          but you will never incur extra costs when you have an accident.
        </p>
      </AccordionItem>

      <AccordionItem title="5. Should I get PIP and/or MedPay?">
        <p>
          When you get Personal Insurance Protection or MedPay, you will be able to
          cover medical bills that are attached to your car accidents. This coverage can be added to a comprehensive
          policy, but it is only needed if you are afraid that your health insurance will not cover you. Also, this
          coverage is helpful if you are traveling out o state often. The policy can prevent you from paying
          out-of-network costs, but it could simply be a drain on your finances if you have health insurance.
        </p>
        <p>
          Covering each of the questions above will bring you to a car insurance decision. Align your needs with the
          five questions above, and you will be able to buy just the right policy for your car.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);