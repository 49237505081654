import React from "react";
import { AccordionItem } from "../../AccordionItem";
import { Accordion } from "../../Accordion";
import { Faq } from "../../Faq";

export const CarRatesFaq = () => (
  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        When you are trying to manage your auto insurance policy, you must make sure that you think over each of the
        eight questions below. These eight questions cover a number of areas where people are unsure about their
        insurance coverage, and you can learn everything you need to know about your policy before you get into a
        troublesome situation.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        When you are shopping for car insurance, you need to make sure that you are very
        clear about what you want. You need to get all your facts straight before you buy your policy, and you need to be
        sure that the policy is just right for you.
      </p>
    </>
  )}>
    <Accordion>



      <AccordionItem title="What Claims Will Raise My Premiums?">
        <p>
          When you are filing claims for your car, you must remember that all these
          claims are different. Some of these claims are going to be due to your error. The police will complete a
          report that will show who was at fault in the accident. When you are found to be at fault, the insurance
          company will discover this in their own investigation. Your premiums are likely to go up because you were the
          person at fault, and they will check your driving record every few months to be sure that they do not have
          reason to raise your premiums.
        </p>
      </AccordionItem>

      <AccordionItem title="What Cars Are Most And Least Expensive To Insure?">
        <p>
          When you purchase your vehicle, you need to make sure that you understand
          how much it would cost to insure your car. The value of the car will point to the price that you must pay in
          premiums, but the performance of the car also points to the price you must pay.
        </p>
        <p>
          A sports car that has an
          amazing engine is going to be fun to drive, but that car is also going to be expensive to insure. The
          insurance company will assume that they must pay out claims on a car that could be easily crashed, and you
          will have to pay premiums that are extremely high.
        </p>
        <p>
          Likewise, a family car that does not have much
          performance is going to allow you to pay less in insurance premiums. A car that has more safety devices is
          going to be cheaper to insure, and the car will be cheaper to insure if it is kept in a safe place.
        </p>
      </AccordionItem>

      <AccordionItem title="What Factors Determine Your Rates?">
        <p>
          Your driving record is the first part of your evaluation when you purchase
          your policy, and you must be honest about what is on your driving record. The insurance company is going to
          use your information to check your driving record, and they will gauge your premiums based on the record they
          see.
        </p>
        <p>
          They also base your premiums on how many people are driving the car. When you have many people
          authorized to drive the car, you will have to pay a premium commensurate with their driving record. When you
          add your teenage child to the policy, you will need to pay a higher premium because that child is under 21.
          The premium will not go down to a true adult rate until the child reaches 25. The same is true of you if you
          are not yet over 25. You will need to pay more money on your premiums if you are not over 25.
        </p>
      </AccordionItem>

      <AccordionItem title="How Important Is Your Credit Score?">
        <p>
          Your credit score plays a part in your insurance premiums, but it is only
          a part. If your credit score is too low, you will need to pay more in premiums to make up for that low credit
          score. You will not be penalized for an average credit score, and it is possible that you will be able to get
          a break on your premium if you have a higher credit score.
        </p>
        <p>
          Your credit score is not required by some
          insurance companies, but the insurance companies that ask for your credit score are going to check to make
          sure that you are making your other payments on-time. They want to make sure that you are a dependable person,
          and they want to make sure that they will not pay out more in claims than they will take in in premiums.
        </p>
      </AccordionItem>

      <AccordionItem title="How Do I Lower My Insurance Rate?">
        <p>
          When you want to lower your insurance rates, you need to take a couple
          steps. Driving safely is going to help you to keep your driving record clean. The longer that you drive
          safely, you will be able to lower your premiums. When you are getting older, you will be able to get lower
          insurance premiums. Most people that are young will simply get lower premiums as they get older, and you will
          see your premiums bottom out when you are 25. If you have a clean driving record for many years, you will be
          able to lower your premiums by changing policies and terms.
        </p>
        <p>
          When you work with your insurance agent, you
          will have a chance to get discounts that you cannot get anywhere else. The insurance agent can give you a
          package that is designed for your family, and they can find packages that match your career. You might be able
          to get a package that is for active-duty military personnel, and you might be able to find a policy that is
          for senior citizens. You have a choice, but you need to ask your insurance agent to help you find something
          that is much cheaper. They can point you in the direction of the right policy.
        </p>
      </AccordionItem>

      <AccordionItem title="What If I Miss A Payment?">
        <p>
          When you miss a payment, you will have a grace period that is based on the
          terms of the policy. You will get a grace period of a typical thirty days before your policy is no longer in
          effect. The insurance company is going to send you a notice that will tell you how long before you have to
          make your payment, and they will send you a notice if your policy is no longer in effect. You can get record
          of your policy being effective when you make good on your payment, and you must remember that the insurance
          company is very strict about their grace periods. You could be without insurance if you are not looking after
          your payments, and you could be pulled over or get in an accident when you do not have coverage.
        </p>
      </AccordionItem>

      <AccordionItem title="Can I pause my insurance coverage if I won’t be driving my car for a few months?">
        <p>
          When you want to pause your car insurance, you need to call the insurance
          company to see what you can do about pausing the policy. There are many companies that will allow for the
          policy to be paused, but you should work with the insurance agent to see what they can do to help you. When
          the insurance company pauses the policy, they will send you notice that the policy is no longer in effect.
        </p>
        <p>
          When you pause the coverage, you may need to pause it for a specific amount of time. There are other
          companies that will allow you to contact them when you are ready for the policy to be in effect again.
          However, there are other times when the insurance company may want to see proof that you are not driving.
          You can submit record of your travel, or you can show that you are not able to drive for a specific period
          of time.
        </p>
        <p>
          When you pause your coverage, you need to remember that it is possible to pause your
          payments. However, there are times when the insurance company is going to ask you to pay a small fee to
          pause the coverage. You need to make sure that you understand the terms of the pause from the insurance
          company before you do so.
        </p>
      </AccordionItem>

      <AccordionItem title="What Do You Do If You Are Denied For Coverage?">
        <p>
          When you are denied for coverage by one company, you are not going to be
          denied by all companies. The companies that offer insurance to you are not all the same. You can apply with
          many companies to shop around for the prices that you think are the best for you. You may be denied for a
          comprehensive policy, but you might be approved for a collision policy. You have to have insurance under the
          law, and you should make sure that you ask the insurance company to tell you which policies you may be
          approved for.
        </p>
        <p>
          You should remember that the coverage you are denied for is just one policy. You can
          select different sorts of policies that are designed for someone just like you. Also, you can change companies
          if you are not sure that you like the company you are with today. There are many ways to get the right
          coverage, but you do not have to sulk over the policy that got away.
        </p>
        <p>
          When you are looking for
          information about your insurance policy, you can read through the questions above. These items are critical
          for your financial well-being, and you need to make sure that the information is clear before you purchase
          your policy.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);