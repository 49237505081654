import React from 'react';
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const MedicareRatesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      If you're interested in seeing what Medicare insurance can do for you, be sure to ask yourself a few important
      questions before getting started. You can find these questions listed below to help introduce eligible citizens to
      getting the care they need.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="Am I eligible for Medicare?">

        <p>
          Applicants to Original Medicare Parts A and B must be United Stated citizens
          or a resident for over five years. In addition, at least one of these must be true:
        </p>
        <ul>
          <li>You are at least 65 years of age. In this case, it is possible to get a Medicare Part A plan for no cost,
            but it you may also need to pay a premium. Factors influencing this decision include whether or not your
            spouse worked for an employer covered by Medicare and the length of employment. For Medicare Part B
            coverage, you will always pay a premium for the plan.
          </li>
          <li>You have received Social Security disability research for at least 24 months for your disability. Age is
            not a factor in this circumstance.
          </li>
          <li>You suffer from ALS and have previously received an insurance payment for Social Security disability.
            Again, age is no factor.
          </li>
          <li>You have been diagnosed with permanent kidney failure and require either a transplant or ongoing dialysis,
            regardless of age.
          </li>
        </ul>
        <p>
          While Medicare is largely thought of as an elderly insurance plan, anyone who meets one of the other
          requirements is eligible for Medicare regardless of age.
        </p>
      </AccordionItem>
      <AccordionItem title="Do I need to renew?">

        <p>
          For the most part, once you have enrolled in Medicare, coverage
          automatically renews with each passing year, regardless of the type of insurance plan you get. As long as you
          keep up to date on premiums, you will generally not have to worry about losing coverage. There are a few
          exceptions, of course, such as:
        </p>
        <ul>
          <li>The plan no longer covers where you live</li>
          <li>The plan has chosen not to renew its contract the next year</li>
          <li>The plan exits the Medicare program</li>
        </ul>
        <p>
          While you won't need to reapply each year, you should always check your coverage options. It's not uncommon
          for cost sharing, provider networks and benefits to change on an annual basis, so comparing it will help
          ensure your needs are being met at an affordable rate.
        </p>
      </AccordionItem>
      <AccordionItem title="Can my spouse and I get a family Medicare plan?">

        <p>
          Medicare is given to citizens on an individual basis, which means there is
          no family plan. Those who qualify generally immediately do so because they've turned 65 or have a disability, so
          they can enroll in the plan they need. Beneficiaries would cover themselves under their own plans rather than
          with a family member.
        </p>
      </AccordionItem>
      <AccordionItem title="When can I sign up for part B?">

        <p>
          You have three opportunities to enroll in Medicare Part B.
        </p>
        <p>
          The initial
          enrollment period begins three months before your 65th birthday, including your birth month. It lasts for seven
          months, ending the final day of the third month following your birthday. To get Part B coverage as soon as you
          turn 65, you'll need to register in those first three months.
        </p>
        <p>
          Failing this, it is possible to enroll
          during the general enrollment period, which takes place between January 1 and March 1 every year. Enrolling here
          will guarantee coverage starting July 1, though you may need to pay a higher monthly cost due to enrolling after
          your 65th birthday.
        </p>
        <p>
          In addition to this, there is a special enrollment period in which you can enroll for
          Medicare Part A or B. This may occur since you were still employed and covered under a health plan. In this
          situation, you could register:
        </p>
        <ul>
          <li>Whenever you are working and are covered by a group employer health plan or union plan.</li>
          <li>Within eight months starting the month after your employment ended.</li>
        </ul>
      </AccordionItem>
      <AccordionItem title="What is Medigap?">

        <p>
          This is privately sold health insurance meant to fill the 'gaps' standing
          between what a beneficiary must pay and what Medicare will pay. In other words, it gives a bit more financial
          relief by assisting with some costs Medicare will not cover. It is better to think of it more as a supplemental
          insurance plan to complement Medicare rather than a second standalone insurance.
        </p>
      </AccordionItem>
      <AccordionItem title="How can I get Medigap insurance?">

        <p>
          There are six months following your 65th birthday and while you're enrolled
          in Part B Medicare insurance. This period permits you to buy any policy in your state, meaning you won't be
          turned down for the policy you've chosen during this period. With careful planning, it's certainly possible to
          truly come out ahead with widespread coverage and a competitive monthly premium cost.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
)