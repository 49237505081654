import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const HomeQuotesFaq = () => {
  return (
    <Faq preText={(
      <p className="text-base text-gray-700 md:text-lg">
        There are so many different Web sites out there that tell you what type of home insurance to get, why their
        insurance is the best, and why there is no other company that offers insurance quite like theirs. They may even
        go so far as to tell which insurance is the best for your situation. What you need to be sure of is the type of
        insurance you need and if the quotes you are getting from these sites is actually accurate. Here are a couple of
        frequently asked questions that can help you start your search to make sure you are not being “had” by these
        sites.
      </p>
    )}>
      <Accordion>
        <AccordionItem title="How can I get the most accurate quote for my situation?">
          <p>
            Sometimes you are given several different options of prepackaged coverages
            when getting a quote online. Don’t use these. Rateinsider.com is an excellent alternative to these sites.
            RateInsider.com offers users several different choices and options to find the best quote for them. Users can
            go through and select exactly what they are going to be using for their own policy, as well as the exact
            deductible amounts and coverage types that they need. Make sure you are also editing where you live and your
            insurance limits to best meet your needs. This will ensure that all of that factors that go into your
            insurance rates are reflected in the final quotation of your policy.
          </p>
        </AccordionItem>

        <AccordionItem title="How can I find out if I am getting the right home insurance coverages for my situation?">
          <p>
            Several online quotation services offer you the option of entering in your
            personal and home information when preparing your quote. You should not look at this as an invasion of your
            privacy or some ruse that will create junk mail for your inbox, but rather as an opportunity to find out what
            you truly need. As well as getting you a more accurate quote, this will also enable the computer software to
            look at the demographic and building information on your home. When all of this is run through the software,
            it will be able to capture the true value of your home to ensure that you are able to then choose the correct
            coverage amounts.
          </p>
        </AccordionItem>

        <AccordionItem title="When should I start my new policy?">
          <p>
            The answer to this question really depends on when your old policy is
            ending. Just like any other type of insurance, a gap in coverage is something that nobody wants. In fact, if
            someone else is a lien holder on your property, you may be breaking your contract by having this gap in
            coverage. With that in mind, be sure to select the last day of your old policy as the first day of your new
            policy. For example, if your old policy is set to expire on January 12, then you need to be sure your new
            policy starts on January 12. This will eliminate any unnecessary gaps in coverage.
          </p>
        </AccordionItem>

        <AccordionItem title="What does the homeowner insurance policy all cover?">
          <p>
            Homeowner insurance covers many things that could go wrong in your house.
            The foremost item that your policy covers is damage to the home itself if it should be affected by a fire,
            vandalism, or other incidents. This coverage will also cover any structures that are adjacent to your home
            that you own, such as any garages or sheds. Personal belongings inside the home are also covered by your
            insurance.
          </p>
          <p>
            As well as physical damage, your insurance also covers damage that is outside of your home
            and other structures. Living expenses are generally covered if you are displaced because of an event that
            causes your home to be temporarily unlivable or unusable. Liabilities may also be covered if they cause injury
            to someone inside or outside of your home as well.
          </p>
        </AccordionItem>

        <AccordionItem title="Do I have enough insurance coverage for my home?">
          <p>
            There is a very easy formula to figure out if you have enough insurance on
            your home. All you have to do is figure out the amount that you would need to rebuild or replace your home and
            other structures on the property if you were subjected to a complete loss. This is the amount of coverage that
            you should have on your home. Of course, the value of your home will change year over year, so it is important
            to keep track of your home’s value and adjust your coverage accordingly.
          </p>
        </AccordionItem>
      </Accordion>
    </Faq>
  );
};