import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const MotocycleQuotesFaq = () => (
  <Faq preText={(
    <p className="text-base text-gray-700 md:text-lg">
      Shopping for motorcycle insurance can be tough, especially because you need to know exactly what services you
      want
      before you make the final decision. You do not want to be tricked into a policy that you don’t even need, and you
      should be aware of your state’s requirements when you do shop. Consider the following questions and answers when
      you
      buy your motorcycle insurance policy.
    </p>
  )}>
    <Accordion>
      <AccordionItem title="How much will motorcycle insurance cost?">
        <p>
          While the amount people spend on motorcycle insurance differs vastly, your age
          and type of bike factor in heavily. For instance, having a small bike you use only to commute to work and a
          clean
          record means that you are paying hundreds each year. On the other hand, you might have a speedy motorcycle
          that
          you use to attend high school and a ticket in your past. Your premium could be several thousand dollars each
          year.
        </p>
        <p>
          The truth is that motorcycle drivers do tend to pay higher rates for the privilege of driving than car
          drivers do. The reason for this is the risky nature of operating a motorcycle. For instance, it is hard for
          auto
          drivers to see motorcycles, especially in their blind spots. Additionally, crashes involving motorcycles are
          often
          more serious than other types of accidents because of the lack of protection they provide.
        </p>
      </AccordionItem>
      <AccordionItem title="Am I required to have a motorcycle insurance?">

        <p>
          Yes, each state does require some amount of liability insurance; however, the
          amount varies from each state to the next. Some states, like Louisiana, offer low rates. Drivers in
          Louisiana are
          required to buy personal injury coverage amounting to about $20,000 in addition to $10,000 for coverage of
          damage.
          Other states, including Alabama and Maine, have the strictest requirements in the country.
        </p>
      </AccordionItem>
      <AccordionItem title="What are the motorcycle insurance requirements in my state?">

        <p>
          Each state has its own requirements for the minimum amount of liability you
          are must provide coverage for. It is likely that you want more than the minimum if you want to protect your
          assets
          completely, but understanding the minimum begins with researching your state’s legal requirements.
        </p>
      </AccordionItem>
      <AccordionItem title="Does my insurance include passenger liability coverage?">

        <p>
          Ultimately, this comes down to who caused the accident. If you are at fault,
          your liability coverage is likely to pay for injuries and property damage you are responsible for paying the
          other
          party. You must have guest passenger liability coverage to cover a passenger riding on your bike with you.
        </p>
      </AccordionItem>
      <AccordionItem title="Do I need a collision insurance? What about comprehensive or uninsured drivers policies?">
        <p>
          While these types of insurance can be incredibly helpful, the choice to take
          them is a personal one. Collision and comprehensive insurance are helpful if you experience damage in an
          accident,
          fire or as a result of vandalism. You may choose to purchase uninsured or underinsured coverage just in case
          you
          are in an accident with a driver who has insufficient insurance to cover damages.
        </p>
      </AccordionItem>
      <AccordionItem title="Can I buy replacement cost coverage for my motorcycle?">

        <p>
          Each insurer and policy comes with its own replacement cost coverage
          guidelines. Some will pay what is described as “book value” for costs, while others will only pay for
          machines
          less than a certain age or for certain models.
        </p>
      </AccordionItem>
      <AccordionItem title="Will motorcycle insurance cover my custom parts, accessories and safety gear?">

        <p>
          This is a valid question, especially considering the time and money many
          riders put into their bikes. Unfortunately, standard coverage does not often extend to custom motorcycle
          parts,
          and you may have to buy optional coverage. You may still find a standard policy that covers safety gear,
          especially helmets and jackets.
        </p>
      </AccordionItem>
      <AccordionItem title="Should I buy coverage for medical payments?">

        <p>
          This is a personal decision; however, medical payment coverage is helpful
          because it covers the cost of your care following an accident. You are covered even if you are at fault for
          the
          accident.
        </p>
      </AccordionItem>
      <AccordionItem title="Can I buy roadside assistance for my motorcycle?">

        <p>
          Yes, with many policies you will be given the option. For motorcycles, this
          tends to cover towing to a repair shop. Some policies also offer trip interruption service.
        </p></AccordionItem>
      <AccordionItem title="What discounts are available for motorcycle drivers on insurance?">

        <p>
          Several discounts are available for motorcyclists, and you can often begin
          your search by checking out your current agency. You may find that a bundle is the perfect choice.
        </p>
        <p>
          You can
          buy an older bike with a small engine that it is not as flash as many other options to save money as well.
          Some
          insurance companies will give you a discount if you take a safety training course and have no claims on your
          insurance once you start driving.
        </p>
      </AccordionItem>
    </Accordion>
  </Faq>
);