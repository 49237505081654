import React from "react";

export const Faq = ({ children, preText, hidePretext = true }) => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20">
      <div>
        {
          !hidePretext && (
            <div className="flex flex-col items-center justify-center mb-16">
              <a href="/" className="mb-6">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-50">
                  <svg className="w-8 h-8 text-light-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
              </a>
              <h2
                className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 text-center sm:text-4xl">
              <span className="relative inline-block">

                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="ec5d8ef5-b853-4714-b94f-df28ec98eeb7"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#ec5d8ef5-b853-4714-b94f-df28ec98eeb7)"
                    width="52"
                    height="24"
                  />
                </svg>

                <span className="relative">How</span>
              </span>{" "}
                <span>RateInsider Works?</span>
              </h2>
              {
                preText ? (
                  <div className="flex flex-col gap-y-2.5">
                    {preText}
                  </div>
                ) : (
                  <p className="text-base text-gray-700 md:text-lg">
                    RateInsider.com is not a licensed insurance company. We do not provide quotes directly to
                    customers.
                    RateInsider.com is a marketplace that connects consumers with insurance carriers. Insurance
                    rates are
                    based on coverage limits, deductibles, driving history, education, occupation, vehicle, location
                    and other
                    factors. Individual rates will vary.
                  </p>
                )
              }
            </div>
          )
        }

        <div className="flex flex-col items-center justify-center mb-4">
          <span className="mb-6">
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-50">
              <svg className="w-8 h-8 text-light-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </span>
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 text-center sm:text-4xl">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="ec5d8ef5-b853-4714-b94f-df28ec98eeb7"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#ec5d8ef5-b853-4714-b94f-df28ec98eeb7)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">Frequently Asked Questions</span>
              </span>
          </h2>
        </div>
        {children}
      </div>
    </div>
  );
};