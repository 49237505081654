import React from 'react';
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const WorkersComponsationQuotesFaq = () => (
  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        Arming yourself with knowledge is key when beginning your search for workers’ compensation insurance. Not
        only can the terminology get lost in translation, but it can also be a stressful process as well. So, get it right
        the first time by choosing the appropriate workers' compensation insurance policy for your company, with
        assistance from RateInsider.com.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        The following 5 questions are being asked with you in mind. They’re
        intended to help you better understand the coverage you have and if it’s the right coverage you need. They might
        help your awareness of workers’ compensation insurance and its wording and/or terminology. Becoming familiar with
        all that your policy says, and covers might just save you from an unexpected problem that’s not covered down the
        road.
      </p>
    </>
  )}>
    <Accordion>
      <AccordionItem title="If I become injured or ill at work that is not due to an accident, will Workers’ Comp still cover me?">

        Yes. However, this depends on the illness or injury. If it is from the
        result of being exposed to various chemicals on the job, stress which leads to digestive illnesses, and
        repetitive motion injuries such as typing, which can lead to carpal tunnel syndrome, for example, workers’
        comp insurance will cover you.
      </AccordionItem>

      <AccordionItem title="If I’m at fault for the accident and my injury at work, can I still receive Workers’ Compensation benefits?">

        Yes. You can receive Workers’ Comp benefits if you’re at fault for your
        injury. However, benefits are not for employees who sustain injuries as a result of being under the influence
        of substances, fooling around, or self-inflicted injuries. Workers’ Comp Insurance is meant to help genuine
        work-related accidents no matter who is at fault.
      </AccordionItem>

      <AccordionItem title="What benefits does Workers’ Comp Insurance offer employees?">

        The benefits you can receive range from temporary to permanent disability
        benefits, permanent total to permanent partial disability benefits, paid medical treatment costs, vocational
        rehabilitation, all depending on the extent of your injury. Benefits vary from state to state, however, they
        usually pay two-thirds of your regular salary.
      </AccordionItem>

      <AccordionItem title="Can I return to the same job?">

        Yes. If your same job is available your employer must allow you to go back
        to it. However, if your position has been filled by someone else your employer can place you in some other
        open position.
      </AccordionItem>

      <AccordionItem title="Should I retain an attorney to file a Workers’ Comp Claim?">

        If your injury or illness is severe, you may want to retain an attorney to
        help you with your claim. If you believe your injury will render you permanently disabled, or for a long time,
        it might be in your best interest to hire a qualified and experienced Workers’ Comp attorney to handle the
        claims process for you.
      </AccordionItem>

    </Accordion>
  </Faq>
)