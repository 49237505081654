import React from 'react';
import { Accordion } from "../../Accordion";
import { AccordionItem } from "../../AccordionItem";
import { Faq } from "../../Faq";

export const PetQuotesFaq = () => (

  <Faq preText={(
    <>
      <p className="text-base text-gray-700 md:text-lg">
        Pet insurance is a type of insurance that covers the costs of illness, accidents, veterinary visits, or even
        the death of pets. It is accepted by all veterinarians who are licensed. It is advisable for all pet owners to
        have insurance cover for their pets, especially if they own multiple pets.It may seem like a lot to pay monthly
        premiums, but it could save them huge sums of money in the case of unexpected accidents or illnesses.
      </p>
      <p className="text-base text-gray-700 md:text-lg">
        Finding the perfect insurance cover can be a very arid task. With more than a few companies to choose from,
        trying to get the best cover at the most optimal price can get very dreary. You have to compare coverage
        policies and rates from the multiple companies that exist to decide which suits you best and fill out tons of
        forms, which not many people have time or the energy for.
      </p>
    </>
  )}>
    <Accordion>


      <AccordionItem title="What are the important factors to consider when choosing a pet insurance policy?">

        <p>
          While cost is a vital factor in deciding which insurance policy you want
          for your pets, you should also consider other factors.
        </p>
        <ul>
          <li>Premium: This is the amount you are required to pay each month for your policy to remain active.</li>
          <li>Deductible: When making an insurance claim, a set amount of money is required of you to pay from your
            pockets on top of the amount that the policy will cover. This is called a deductible.
          </li>
          <li> The reimbursement rate refers to the percentage that the insurance provider will cover after you have
            paid your deductible. The pet owner pays for the medical bill; they claim a reimbursement from the
            insurance provider.
          </li>
          <li>Ability to customize your insurance plan in case your needs change in the future, instead of getting a
            new plan.
          </li>
          <li>The policy limitations and potential savings</li>
        </ul>
        <p>
          The entire decision-making and application process is very complex and confusing. People end up choosing
          the wrong policy or one that covers needs they did not necessarily have, thus making it too expensive.</p>
        <p>
          That is why a website like RateInsider.com exists, to help people with the whole process. It collects
          information from different insurance companies in the market and puts out this information in short,
          simplified terms. It takes minutes to fill out details of the type of coverage you want, and they connect
          you with the appropriate agents who will help you apply for the exact coverage that suits your needs, thus
          saving you unnecessary costs.
        </p>
      </AccordionItem>

      <AccordionItem title="What does pet insurance cover?">

        <p>
          Pet insurance covers overall wellness, that is, illnesses and veterinarian
          visits. It also covers the cost of unexpected accidents.
        </p>
        <p>
          It, however, doesn't cover the cost of chronic
          illnesses that already pre-existed before the purchase of the policy.
        </p>
      </AccordionItem>

      <AccordionItem title="Can I afford term life insurance for my pet?">

        <p>
          Apart from pets' health and wellness, some insurance companies also offer
          life insurance policies for pets. This covers funeral or cremation expenses in case of the sudden death of
          your pets.
        </p>
        <p>
          Apart from death, life insurance could also cover theft and the disappearance of pets,
          depending on the insurance provider you choose. This type of coverage is useful for people who own pets that
          do shows to cover for income that would have been generated in the future. It is also useful to the rare and
          pure breed of pets that are expensive to purchase.
        </p>
        <p>
          Term life insurance may seem to be costly, but the
          premiums paid are relatively low compared to the expenses incurred when a pet dies, especially if it had
          medical bills before its death.Insurance comes in handy for people who consider pets as part of their families
          as they can afford to give the pets a decent send-off without breaking the bank.
        </p>
      </AccordionItem>

      <AccordionItem title="What are the advantages of term life insurance?">

        <p>
          If a pet was generating income for you, the insurance provider compensates
          for the future income that would have been generated based on the value of the pet.
        </p>
        <p>
          You don't have to
          worry about the funeral expenses when your pets die unexpectedly.You are compensated for the pet's financial
          value in case of sudden disappearance or theft, especially for extremely rare breeds.
        </p>
      </AccordionItem>

      <AccordionItem title="How to decide on the best pet insurance for you?">

        <p>
          The optimal insurance policy is supposed to cover your financial needs in
          case of an unexpected occurrence like illness or accident.
        </p>
        <p>
          The level of care required to depend on the
          type of pet, their age as well species. Also, put into consideration how often your pet needs veterinary
          check-ups. Older pets will require more veterinary visits than younger ones.
        </p>
      </AccordionItem>

      <AccordionItem title="The role played by RateInsider.com in pet insurance?">

        <p>
          RateInsider.com is not an insurance company in any way. It is a referral
          site that makes all information about different company policies and rates and helps insurance buyers get the
          best policies based on their individual needs. Insurance buyers have to buy policies through the insurance
          agents provided by the site.
        </p>
        <p>The site is completely free to use. Clients don't pay a dime to get
          connected with insurance companies</p>
      </AccordionItem>

      <AccordionItem title="How RateInsider.com works?">

        <p>
          RateInsider.com collaborates with agents from top insurance companies to
          help potential clients access insurance policies more efficiently. If interested in pet insurance, you'll only
          need to follow a few steps on the website.
        </p>
        <ol>
          <li>Fill out an online form by answering a few simple questions in minutes.</li>
          <li>Give details on the level of coverage you want, your current location, and your current insurance policy
            (if any).
          </li>
          <li>When you're done giving all the required details, RateInsider.com searches for insurance companies that
            offer policies that match your needs best.
          </li>
          <li>Now you can wait to be contacted by the insurance companies that have been selected for you.Meanwhile,
            you can also get insurance policy quotes directly from a list curated on the website.
          </li>
          <li>Once you get quotes from the different companies, compare the differences in their pricing and match
            them against the policies, then pick the deal that appeases you best.
          </li>
        </ol>
      </AccordionItem>
    </Accordion>
  </Faq>
)