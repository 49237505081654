import React from "react";
import {
  CarIndexFaq,
  CarQuotesFaq,
  CarRatesFaq,
  MotorcycleIndexFaq,
  MotocycleQuotesFaq,
  MotorcycleRatesFaq,
  HomeIndexFaq,
  HomeRatesFaq,
  HomeQuotesFaq,
  HealthIndexFaq,
  HealthQuotesFaq,
  HealthRatesFaq,
  MedicareIndexFaq,
  MedicareQuotesFaq,
  MedicareRatesFaq,
  LifeIndexFaq,
  LifeQuotesFaq,
  LifeRatesFaq,
  PetIndexFaq,
  PetRatesFaq,
  PetQuotesFaq,
  RentersIndexFaq,
  RentersQuotesFaq,
  RentersRatesFaq,
  WorkersComponsationIndexFaq,
  WorkersComponsationQuotesFaq,
  WorkersComponsationRatesFaq,
} from "./";

const faqMapper = {
  car: {
    index: CarIndexFaq,
    rates: CarRatesFaq,
    quotes: CarQuotesFaq,
  },
  motorcycle: {
    index: MotorcycleIndexFaq,
    rates: MotorcycleRatesFaq,
    quotes: MotocycleQuotesFaq,
  },
  home: {
    index: HomeIndexFaq,
    rates: HomeRatesFaq,
    quotes: HomeQuotesFaq,
  },
  health: {
    index: HealthIndexFaq,
    rates: HealthRatesFaq,
    quotes: HealthQuotesFaq,
  },
  medicare: {
    index: MedicareIndexFaq,
    rates: MedicareRatesFaq,
    quotes: MedicareQuotesFaq,
  },
  life: {
    index: LifeIndexFaq,
    rates: LifeRatesFaq,
    quotes: LifeQuotesFaq,
  },
  pet: {
    index: PetIndexFaq,
    rates: PetRatesFaq,
    quotes: PetQuotesFaq,
  },
  renters: {
    index: RentersIndexFaq,
    rates: RentersRatesFaq,
    quotes: RentersQuotesFaq,
  },
  "workers-compensation": {
    index: WorkersComponsationIndexFaq,
    rates: WorkersComponsationRatesFaq,
    quotes: WorkersComponsationQuotesFaq,
  },
};

export const faqHandler = (slug) => {
  return faqMapper[slug]
};